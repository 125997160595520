import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CompanyDetailSkeleton from "../../components/Loaders/CompanyDetail";
import UsersTable from "../../components/adminTable/UsersTable";
import { GetSingleCompany, GetUsers } from "../../utils/admin/admin.service";
import CompanyCardDetail from "../../components/shared/CompanyCard.Detail";
import AdditionalDetail from "../../components/shared/AdditionalDetail";
import AdditionalDetailSkeleton from "../../components/Loaders/AdditionalDetail";
import CompanyUserAdd from "./CompanyUserAdd";

const CompanyDetails = () => {
    const { companyId } = useParams();
    const [company, setCompany] = useState(null);
    const [additional, setAditional] = useState();
    const [users, setUsers] = useState([]);
    const [usersOption, setUsersOption] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [loading, setLoading] = useState(true);



    const GetCompany = async () => {
        let response;
        setLoading(true);
        try {
            const companyToFetch = companyId;
            if (companyToFetch) {
                response = await GetSingleCompany(companyToFetch);
                setCompany(response?.data?.company);
                setUsers(response?.data?.company?.users)
                setAditional(response?.data?.data)
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const GetUser = async () => {
        let response;
        // setLoading(true);
        try {
            response = await GetUsers();
            setUsersOption(response?.data?.data)
            // setLoading(false);
        } catch (error) {
            // setLoading(false);
        }
    };
    useEffect(() => {
        if (companyId) {
            GetCompany();
            GetUser();
        }
        // eslint-disable-next-line
    }, [companyId]);

    const FormClose = () => setShowAddUserModal(false);

    return (
        <Container fluid>
            <div className="mt-4">
                <div>
                    {loading ? (
                        <CompanyDetailSkeleton />
                    ) : (
                        <CompanyCardDetail company={company} reCall={GetCompany} />
                    )}
                </div>
                {loading ? (
                    <AdditionalDetailSkeleton />
                ) : (
                    <AdditionalDetail company={company} additional={additional} reCall={GetCompany} />
                )}
                <div>
                </div>
                {!loading && (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 style={{ color: '#4E4E4E' }} className="mt-4">
                            Company Users
                        </h4>
                        <Button
                            className="btn-custom px-3 fw-bold d-flex align-items-center"
                            onClick={() => setShowAddUserModal(true)}
                            style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
                        >
                            <span className="me-2">&#43;</span>
                            Add User
                        </Button>
                    </div>
                )}
                <UsersTable tableBody={users} companyId={companyId} loading={loading} reCall={GetCompany} />
                <CompanyUserAdd
                    reCall={GetCompany}
                    companyId={companyId}
                    show={showAddUserModal}
                    close={FormClose}
                    usersOption={usersOption}
                    users={users}
                />
            </div>
        </Container>
    );
};

export default CompanyDetails;
