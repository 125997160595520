import React from 'react'
import { Image } from 'react-bootstrap'
import table from '../../assets/svg/table.svg'
const TableHeading = ({ heading, icon }) => {
    return (
        <div className='d-flex gap-3 align-items-center'>
            <div style={{ width: "10px" }} className='mb-1'>
                <Image src={icon || table} alt="I" />
            </div>
            <h6 className='mb-0' style={{ color: "#09332C" }} >{heading}</h6>
        </div>
    )
}

export default TableHeading
