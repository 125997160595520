import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomCardMobile from '../Mobilecomponents/CustomCardMobile';

const CustomCard = ({ data, loading }) => {
    const defaultCards = [
        { label: 'Loading', value: 'Loading' },
        { label: 'Loading', value: 'Loading' },
        { label: 'Loading', value: 'Loading' },
        { label: 'Loading', value: 'Loading' }
    ];

    const cardsToDisplay = loading ? defaultCards : data;

    return (
        <>
            <Row className="gap-md-3 px-lg-5 px-2 d-lg-flex d-none justify-content-center">
                {cardsToDisplay.map((item, index) => (
                    <Col
                        key={index}
                        className="col-lg col-md-5 col-12 rounded-4 p-3 mt-md-0 mt-4"
                        style={{ background: "var(--color1)", height: "100px" }}
                    >
                        <p className="mb-1 small" style={{ color: "var(--color4)" }}>
                            {loading ? (
                                <Skeleton
                                    width={80}
                                    height={20}
                                    baseColor="#ffe521"
                                    highlightColor="#fff899"
                                />
                            ) : (
                                item?.label
                            )}
                        </p>
                        {loading ? (
                            <div className="ps-2">
                                <div className="loader-small"></div>
                            </div>
                        ) : (
                            <h4 style={{ color: "var(--color4)" }}>{item?.value}</h4>
                        )}
                    </Col>
                ))}
            </Row>
            <div className='d-lg-none d-block'>
                <CustomCardMobile cardsToDisplay={cardsToDisplay} loading={loading} />
            </div>

        </>
    );
};

export default CustomCard;
