import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, Row, Col } from 'react-bootstrap';

const AdditionalDetailSkeleton = () => {
    return (
        <>

            <div className="d-flex align-items-center justify-content-between">
                <h4 style={{ color: '#4E4E4E' }} className="my-4">
                    <Skeleton width={180} />
                </h4>
                <h4 style={{ color: '#4E4E4E' }} className="my-4">
                    <Skeleton width={180} />
                </h4>
            </div>
            <Card className="mb-4 border-0 shadow">
                <Card.Body>
                    {/* Skeleton for Company and Admin Information */}
                    <Row className='p-4'>
                        {/* Company Information Skeleton */}
                        <Col md={6}>
                            <div className="p-2">
                                <Skeleton width="100%" height={20} className="mb-2" />
                                <Skeleton width="90%" height={20} className="mb-2" />
                                <Skeleton width="80%" height={20} className="mb-2" />
                                <Skeleton width="70%" height={20} />
                            </div>
                        </Col>

                        {/* Admin Information Skeleton */}
                        <Col md={6}>
                            <Skeleton width={150} height={25} className="mb-3" />
                            <div className="p-2">
                                <Skeleton width="100%" height={20} className="mb-2" />
                                <Skeleton width="90%" height={20} className="mb-2" />
                                <Skeleton width="80%" height={20} />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default AdditionalDetailSkeleton;
