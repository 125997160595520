import { useEffect, useState } from "react";
import { Button, Card, Container, Dropdown } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight, FaMinus, FaPlus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import UsRegion from '../../assets/maps/gz_2010_us_040_00_20m (1).json'
const geoUrlWorld = "https://raw.githubusercontent.com/datasets/geo-countries/master/data/countries.geojson";
const geoUrlFrance = "https://raw.githubusercontent.com/gregoiredavid/france-geojson/refs/heads/master/regions.geojson";
const geoUrlUs = UsRegion;
const geourlUk = "https://raw.githubusercontent.com/martinjc/UK-GeoJSON/refs/heads/master/json/administrative/eng/topo_lad.json";


const MobileGeoChart = ({ currentData, worldwide, toggleView, selectedMap, setSelectedMap, mapRef, handleResize, loading, zoom, setZoom, pieChartPercentage }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [tooltipContent, setTooltipContent] = useState("");
    const itemsPerPage = 4;

    const handleZoomIn = () => {
        setZoom(prevZoom => prevZoom * 1.2);
    };

    const handleZoomOut = () => {
        setZoom(prevZoom => prevZoom / 1.2);
    };

    useEffect(() => {
        setZoom(prevZoom => prevZoom / 1.2);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, [selectedMap]);

    const totalPages = Math.ceil(currentData?.length / itemsPerPage);

    const mapWidth = 800;
    const mapHeight = 600;


    const paginatedData = currentData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const onPrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const onNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const getColorForValue = (value) => {
        if (value >= pieChartPercentage?.high) return "var(--color1)";
        if (value >= pieChartPercentage?.low) return "var(--color2)";
        return "var(--color3)";
    };
    const getGeographyUrl = () => {
        console.log(selectedMap)
        switch (selectedMap) {
            case 'FR':
                return geoUrlFrance;
            case 'US':
                return geoUrlUs;
            case 'UK':
                return geourlUk;
            default:
                return geoUrlWorld;
        }
    };
    const handleMapSelection = (eventKey) => {
        setSelectedMap(eventKey);
        toggleView();
    };
    return (
        <Container fluid>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <Button onClick={handleZoomIn} className="btn-custom m-1 btn-sm">
                        <FaPlus />
                    </Button>
                    <Button onClick={handleZoomOut} className="btn-custom m-1 btn-sm">
                        <FaMinus />
                    </Button>
                </div>
                {/* <Dropdown onSelect={handleMapSelection}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {selectedMap === 'worldwide' ? "Show Worldwide" :
                            selectedMap === 'FR' ? "Show France Cities" :
                                selectedMap === 'US' ? "Show US Cities" :
                                    selectedMap === 'UK' ? "Show UK Cities" : "Show Worldwide"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-menu-drop">
                        <Dropdown.Item eventKey="worldwide">Worldwide</Dropdown.Item>
                        <Dropdown.Item eventKey="FR">France Cities</Dropdown.Item>
                        {!cityFranceMap && (
                            <>
                                <Dropdown.Item eventKey="US">US Cities</Dropdown.Item>
                                <Dropdown.Item eventKey="UK">UK Cities</Dropdown.Item>
                            </>
                        )}
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
            <div ref={mapRef} style={{ position: 'relative' }}>
                <ComposableMap
                    zoomAndPan={false}
                    projection="geoMercator"
                    projectionConfig={{ scale: selectedMap === 'worldwide' ? 130 : selectedMap === 'FR' ? 2800 : selectedMap === 'US' ? 950 : 3800 }}

                >
                    <ZoomableGroup filterZoomEvent={({ constructor: { name } }) => {
                        console.log("evt", name);
                        return false;
                    }} center={selectedMap === 'worldwide' ? [-29.60, -3.78] : selectedMap === 'FR' ? [2.2, 46.8] : selectedMap === 'US' ? [-95.2129822712537, 38.00772954367041] : [-0.9943731097448041, 52.05256747968059]} zoom={zoom} disablePanning={true} onMoveEnd={({ coordinates, zoom }) => {
                        console.log(coordinates, zoom)
                    }}>
                        <Geographies geography={getGeographyUrl()} >
                            {({ geographies }) =>
                                geographies?.map((geo) => {
                                    let locationData;
                                    if (selectedMap === 'worldwide') {
                                        locationData = currentData?.find(d =>
                                            d.name === geo.properties.ISO_A2 ||
                                            d.name === geo.properties.ISO_A3 ||
                                            (geo.properties.ISO_A2 === 'GB' && d.name === 'UK') ||
                                            (geo.properties.ISO_A3 === 'GBR' && d.name === 'UK')
                                        );
                                    }
                                    else if (selectedMap === 'FR') {
                                        locationData = currentData?.find(d => d.name === geo.properties.nom);
                                    } else if (selectedMap === 'US') {
                                        locationData = currentData?.find(d => d.name === geo.properties.NAME);
                                    } else {
                                        // currentData?.find(d => d.name === geo.properties.ISO_A2 || d.name === geo.properties.ISO_A3)
                                        locationData = currentData?.find(d => d.name === geo.properties.LAD13NM);
                                    }
                                    const fillColor = locationData ? getColorForValue(parseFloat(locationData.value)) : "var(--color3)";
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            fill={fillColor}
                                            stroke="#000000"
                                            strokeWidth={0.5}
                                            onMouseEnter={() => {
                                                let name;
                                                if (selectedMap === 'worldwide') {
                                                    name = geo.properties.ADMIN
                                                }
                                                else if (selectedMap === 'FR') {
                                                    name = geo.properties.nom
                                                } else if (selectedMap === 'US') {
                                                    name = geo.properties.NAME
                                                } else {
                                                    name = geo.properties.LAD13NM
                                                }
                                                setTooltipContent(name);
                                            }}
                                            onMouseLeave={() => {
                                                setTooltipContent("");
                                            }}
                                            style={{

                                                hover: {
                                                    outline: "none"
                                                },
                                                pressed: {
                                                    outline: "none"
                                                },
                                                default: {
                                                    outline: 'none'
                                                }

                                            }}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                    </ZoomableGroup>
                </ComposableMap>
                {!loading &&
                    <Card className="pb-2 mt-4 position-card-tool px-3 border-0 bottom-pos">
                        <div className="color-bar mt-2" style={{ textAlign: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '100px', height: '6px', backgroundColor: 'var(--color1)' }}></div>
                                <div style={{ width: '100px', height: '6px', backgroundColor: 'var(--color2)' }}></div>
                                <div style={{ width: '100px', height: '6px', backgroundColor: 'var(--color3)' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3px' }}>
                                <div style={{ width: '100px', textAlign: 'center', fontSize: '10px' }}>{`> ${pieChartPercentage?.high}%`}</div>
                                <div style={{ width: '100px', textAlign: 'center', fontSize: '10px' }}>{`${pieChartPercentage?.low}% - ${pieChartPercentage?.high}%`}</div>
                                <div style={{ width: '100px', textAlign: 'center', fontSize: '10px' }}>{`< ${pieChartPercentage?.low}%`}</div>
                            </div>
                        </div>
                    </Card>
                }
            </div>
            {tooltipContent && (
                <div style={{
                    position: 'absolute',
                    background: 'rgba(255, 255, 255, 0.8)',
                    border: '1px solid #ccc',
                    padding: '5px',
                    borderRadius: '5px',
                    pointerEvents: 'none',
                }}>
                    {tooltipContent}
                </div>
            )}

            <div className="container-fluid my-5 p-3" style={{ backgroundColor: "#f8f9fa", borderRadius: "8px", }}>
                {loading ? (
                    Array.from({ length: 4 })?.map((_, index) => (
                        <Skeleton key={`skeleton-${index}`} height={30} style={{ marginBottom: '5px' }} />
                    ))
                ) : paginatedData?.length === 0 ? (
                    <p className="text-center">No Data Available</p>
                ) : (
                    paginatedData?.map((m) => (
                        <div key={m.name} className="d-flex justify-content-between align-items-center mb-2" style={{ padding: "5px 0", borderBottom: "1px solid #ddd" }}>
                            <div className="d-flex align-items-center">
                                <div style={{ width: "3px", height: "20px", backgroundColor: getColorForValue(m.value), marginRight: "10px" }} />
                                <p className="mb-0 fs-12">{m.name}</p>
                            </div>
                            <div className="text-end fs-12">{m.value}%</div>
                        </div>

                    ))
                )}
                {totalPages > 1 && currentData?.length > 0 && (
                    <div className="d-flex gap-3 justify-content-end mt-2">
                        <Button className='btn-custom rounded-3 small p-1 px-2' onClick={onPrevious} disabled={currentPage === 1}>
                            <FaAngleLeft />
                        </Button>
                        <Button className='btn-custom rounded-3 small p-1 px-2' onClick={onNext} disabled={currentPage === totalPages}>
                            <FaAngleRight />
                        </Button>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default MobileGeoChart;
