import Bar from '../components/charts/Bar';
import GeoChart from '../components/charts/GeoChart';
import ChartHeading from '../components/shared/ChartHeading';
import CustomCard from './PdfCustomCard';
import DashboardTable from '../components/shared/Table';
import TableHeading from '../components/shared/TableHeading';
import Pagination from '../components/shared/TablePagination';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getChartData, getCityChart, getCountryChart, getStateTableData, getStatsData } from '../services/adminService/adminService';
import PdfHeader from '../components/shared/PdfHeader';
import PdfFooter from '../components/shared/PdfFooter';
const PdfGeo = ({ selectedRange, showImage }) => {
    const [companyId, setCompanyId] = useState(localStorage.getItem('selectedCompany'));
    const [zoom, setZoom] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState(true);
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    // const[tableData,setTableDate]
    const [chartData, setChartData] = useState(null);
    const [pieChartData, setPieChartData] = useState(null);
    const [pieChartCountryData, setPieChartCountryData] = useState(null);
    const [pieChartPercentage, setPieChartPercentage] = useState(null);
    const [stats, setStats] = useState(null);
    const [ftdShow, setFtdSow] = useState();
    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const GeoHeading = [
        "Rank",
        "STATE",
        tableData?.length > 0 ? Object?.keys(tableData[0])[2] : "FTD’s (unique)",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[3]}` : "%FTD’s (unique)",
    ];
    const GeoHeading2 = [
        "Rank",
        "STATE",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[2]}` : "%FTD’s (unique)",
    ];
    const tableHeadings = () => {
        if (ftdShow) return GeoHeading;
        return GeoHeading2;
    };
    // const tableBody = tableData?.map((data) => [
    //     data?.Rank,
    //     data?.State,
    //     data?.FTDsUnique,
    //     `${data?.FTDsPercent}%`,
    // ]) || [];
    const tableBody = tableData?.map((data) => {
        const row = [
            data?.Rank,
            data?.State,
            ...(ftdShow ? [Object.values(data)[2]] : []),
            ...(ftdShow ? [`${Object?.values(data)[3]}%`] : [`${Object?.values(data)[2]}%`]),

            // `${Object.values(data)[2]}%`,
        ];
        return row.filter(value => value != null);
    }) || [];
    const dashboardCards = stats?.dynamicResults
        ? Object.entries(stats.dynamicResults).map(([label, value], index) => ({
            label: label,
            value: index === 0
                ? `${new Intl.NumberFormat('fr-FR').format(value)} ${stats?.currency || ''}`
                : `${new Intl.NumberFormat('fr-FR').format(value)}`,
        }))
        : [];
    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getStatsData(formattedStartDate, formattedEndDate, companyId);
            setStats(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const getChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setChartLoading(true);
            const response = await getChartData(formattedStartDate, formattedEndDate, companyId);
            setChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setChartLoading(false);
        } finally {
            setChartLoading(false);
        }
    }
    const getPieChartCountry = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieChartLoading(true);
            const response = await getCountryChart(formattedStartDate, formattedEndDate, companyId);
            setPieChartCountryData(response?.data?.data?.dashboardPie);
            setPieChartPercentage(response?.data?.data?.percDiffer)

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getPiechartcity = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieChartLoading(true);
            const response = await getCityChart(formattedStartDate, formattedEndDate, companyId);
            setPieChartData(response?.data?.data?.dashboardPie);
            setPieChartPercentage(response?.data?.data?.percDiffer)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getTable = async (page, formattedStartDate, formattedEndDate) => {
        try {
            setTableLoading(true);
            const response = await getStateTableData(formattedStartDate, formattedEndDate, page, 7, companyId);
            setTableData(response?.data?.data?.data);
            setTotalPages(response?.data?.data?.totalPages);
            setFtdSow(response?.data?.data?.ftdEnabled)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setTableLoading(false);
        } finally {
            setTableLoading(false);
        }
    }
    useEffect(() => {
        console.log("Company Id again", companyId)

        const handleCompanyChange = () => {
            setCompanyId(localStorage.getItem('selectedCompany'));
        };
        window.addEventListener('user-update', handleCompanyChange);

        return () => {
            window.removeEventListener('user-update', handleCompanyChange);
        };
    }, [companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getStats(formattedStartDate, formattedEndDate);
            getChart(formattedStartDate, formattedEndDate);
            getPieChartCountry(formattedStartDate, formattedEndDate);
            getPiechartcity(formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getTable(currentPage, formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, currentPage, companyId]);
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const [currentData, setCurrentData] = useState([]);
    const [worldwide, setWorldwide] = useState(false);
    useEffect(() => {
        setCurrentData(pieChartData);
        // eslint-disable-next-line
    }, [pieChartData]);
    const handleResize = () => {
        if (mapRef.current) {
            mapRef.current.style.width = '100%';
            mapRef.current.style.height = '100%';
        }
    };
    const toggleView = () => {
        setZoom(1);
        handleResize();
        setWorldwide(prev => {
            const newValue = !prev;
            if (newValue) {
                // fetchCountryData();
                setCurrentData(pieChartCountryData);

            } else {
                setCurrentData(pieChartData);
            }
            return newValue;
        });
    };
    const mapRef = useRef();
    return (
        <div id="pdf" className='pdf-size'>
            <div style={{
                backgroundImage: showImage
                    ? "url('https://res.cloudinary.com/djmk3gpgr/image/upload/v1730396033/skhbjvc4cy1zxtzudjvm.png')"
                    : "none",
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                backgroundPosition: 'top center'
            }}
            >
                <PdfHeader showImage={showImage} />
                <Container className={` ${showImage ? 'px-5' : ''}`} fluid>
                    <CustomCard data={dashboardCards} loading={loading} />
                    <Row>
                        <Col xs={8} >
                            <div className='bg-white rounded-4 p-4 mt-4' style={{ height: "90%" }}>
                                <ChartHeading heading={"Performances"} />
                                <Bar height="95%" data={chartData} loading={chartLoading} />
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='bg-white rounded-4 p-4 mt-4  ' style={{ height: "90%" }}>
                                <ChartHeading heading={"Performances"} />
                                <div className='my-3 mb-5'>
                                    <GeoChart mapRef={mapRef} handleResize={handleResize}
                                        currentData={currentData} worldwide={worldwide} toggleView={toggleView}
                                        loading={pieChartLoading}
                                        zoom={zoom}
                                        setZoom={setZoom}
                                        pieChartPercentage={pieChartPercentage}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={`${showImage ? 'pb-5' : ''}`}>
                            <div className={`px-5 pt-3 bg-white rounded-4 m-1 ${showImage ? '' : ''}`}>
                                <div className='d-flex justify-content-between  align-items-center mb-5'>
                                    <TableHeading heading={"By Campaign"} />
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onNext={handleNext}
                                        onPrevious={handlePrevious}
                                    />
                                </div>
                                <DashboardTable
                                    tableBody={tableBody}
                                    tableHeadings={tableHeadings()}
                                    loading={tableLoading}
                                />                </div>
                        </Col>
                    </Row>
                </Container >
            </div>
            <PdfFooter showImage={showImage} />
        </div>
    );
};

export default PdfGeo;
