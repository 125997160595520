import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Image } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import logo from '../assets/logo/Logo.svg';
import { LoginForm } from '../services/authentication/authentication.service';
import { Link, useNavigate } from 'react-router-dom';
import { AuthVerify } from '../utils/auth.utils';
const Login = () => {
    const navigate = useNavigate();
    const verify = AuthVerify();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email || !password) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill in both fields.',
            });
            setLoading(false);
            return;
        }
        try {
            const response = await LoginForm({ email, password })
            const user = response?.data?.data?.newUserInfo
            const logo = response?.data?.data?.logo
            const userWithLogo = {
                ...user,
                logo: logo,
            };
            localStorage.setItem('user', JSON.stringify(userWithLogo));
            navigate('/overview')
        } catch (err) {
            console.log("Error", err)
            Swal.fire({
                icon: 'error',
                title: err?.response?.data?.error || 'Network Error',
                text: 'Failed to log in. Please try again later.',
            });
            setLoading(false);
        }
    };
    useEffect(() => {
        if (verify) {
            navigate(`/`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, verify]);

    const star = <span className='text-danger'>*</span>;
    return (
        <Container className='LoginScreen' fluid>
            <Row>
                <Col>
                    <div className="card border-0 p-md-4 rounded-4">
                        <div className="card-body">
                            <div style={{ height: "50px" }} className="d-flex justify-content-center my-md-2 mt-5">
                                <Image src={logo} alt="kypi" />
                            </div>
                            <h3 className="text-center">
                                <span style={{ color: "var(--color4)" }}>Welcome to your</span>
                                <span style={{ color: "var(--color2)" }} className='bg-up px-2'>
                                    dashboard
                                </span>
                            </h3>
                            <p className="text-center mb-5 " style={{ color: "var(--color2)" }}>Please log in to access your statistics</p>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label style={{ color: "var(--color4)" }}>Email{star}</Form.Label>
                                    <Form.Control
                                        className="rounded-3 py-2 "
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mt-3 position-relative">
                                    <Form.Label style={{ color: "var(--color4)" }}>Password{star}</Form.Label>
                                    <Form.Control
                                        className="rounded-3 py-2   "
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <span
                                        className="position-absolute"
                                        style={{ right: '10px', top: '40px', cursor: 'pointer', color: "var(--color2)" }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </Form.Group>
                                <div> <Button type="submit" className="my-4 bg-login" disabled={loading}>
                                    {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Log in'}
                                </Button></div>
                                <p className="text-center   ">
                                    If you have any questions, please contact us at
                                    <Link className='text-decoration-none px-1' style={{ color: "var(--color2)" }} to="mailto:contact@kypi.io">contact@kypi.io</Link>
                                </p>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
