import React, { useCallback, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const BudgetTabsSelection = ({ formData, setFormData }) => {
    const tabOptions = useMemo(() => ([
        { value: 'all', label: 'Select All' },
        { value: 'overview', label: 'Overview' },
        { value: 'overviewPerDay', label: 'Overview Per Day' },
        { value: 'metrics', label: 'Metrics' },
        { value: 'creatives', label: 'Creatives' }

    ]), []);

    const handleSelectChange = useCallback((selectedOptions, name) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            setFormData(prev => ({
                ...prev,
                [name]: tabOptions
                    .filter(option => option.value !== 'all')
                    .map(option => option.value)
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: selectedOptions
                    .filter(option => option.value !== 'all')
                    .map(option => option.value)
            }));
        }
    }, [setFormData, tabOptions]);

    return (
        <div>
            <Row className="mt-3">
                <Col xs={12}>
                    <Form.Group controlId="formCategory">
                        <Form.Label className="text-secondary">Select Budget Tabs to Show</Form.Label>
                        <Select
                            isMulti
                            options={tabOptions}
                            value={tabOptions.filter(option =>
                                formData?.budgetTabs?.includes(option.value) && option.value !== 'all'
                            )}
                            onChange={selectedOptions => handleSelectChange(selectedOptions, 'budgetTabs')}
                            closeMenuOnSelect={false}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
};

export default BudgetTabsSelection;
