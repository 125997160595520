import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Alert } from 'react-bootstrap';
import { CountrySelect } from 'react-country-state-city';
import { useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { adduser } from '../../utils/admin/admin.service';
import Swal from 'sweetalert2';

const AddUser = ({ show, close, reCall }) => {
    const location = useLocation();
    const [error, setError] = useState({});
    const [errorResponse, setErrorResponse] = useState(null);
    const { userData } = location.state || {};
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        country: '',
        useremail: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userData) {
            setFormData({
                firstname: userData?.firstname,
                lastname: userData?.lastname,
                useremail: userData?.useremail,
                phone: userData?.phone,
                password: '',
                confirmPassword: '',
            });
        }
    }, [userData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setError((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.firstname) {
            formErrors.firstname = "First name is required";
        }
        if (!formData.lastname) {
            formErrors.lastname = "Last name is required";
        }
        if (!formData.useremail) {
            formErrors.useremail = "Email address is required";
        }
        if (!formData.password) {
            formErrors.password = "Password is required";
        }
        if (!formData.confirmPassword) {
            formErrors.confirmPassword = "Confirm password is required";
        }
        if (formData.password !== formData.confirmPassword) {
            formErrors.confirmPassword = "Passwords do not match";
        }

        setError(formErrors);
        return Object.keys(formErrors)?.length === 0;
    };

    const handleFormSubmit = async () => {
        if (validateForm()) {
            handleCreate();
        }
    };

    const handleCreate = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to create this user.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            confirmButtonText: "Yes, create it!",
            cancelButtonText: "No, cancel",
            allowOutsideClick: false,
            preConfirm: () => {
                Swal.showLoading();
                return new Promise(async (resolve, reject) => {
                    try {
                        const userDataToSend = {
                            ...formData,
                            confirmPassword: undefined,
                        };
                        await adduser(userDataToSend);
                        resolve();
                    } catch (error) {
                        setLoading(false);
                        setErrorResponse(error?.response?.data?.error || 'Network Error');
                        reject(error?.response?.data?.error || 'Network Error');
                    }
                });
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Created!",
                        text: "The user has been created successfully",
                        icon: "success",
                        confirmButtonColor: "#28a745",
                    });
                    setFormData({
                        firstname: '',
                        lastname: '',
                        country: '',
                        useremail: '',
                        phone: '',
                        password: '',
                        confirmPassword: '',
                    });
                    close();
                    reCall();
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error || 'An error occurred while creating the user.',
                    icon: "error",
                    confirmButtonColor: "#dc3545",
                });
            });
    };


    const handleCancel = () => {
        close();
        setError({});
        setFormData({
            firstname: '',
            lastname: '',
            country: '',
            useremail: '',
            phone: '',
            password: '',
            confirmPassword: '',
        });
    };

    return (
        <Modal show={show} onHide={close} centered size="lg" className='py-0 my-0' keyboard={false} backdrop="static">
            <Modal.Header className='border-0' closeButton />
            <Container>
                <Form className='pb-3 px-4'>
                    <h3 className='text-center mb-4 text-box' style={{ color: "var(--primary-color)" }}>Add User</h3>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId='formFirstName'>
                                <Form.Label className='text-custom my-2'>First Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type='text'
                                    name='firstname'
                                    value={formData.firstname}
                                    onChange={handleInputChange}
                                    placeholder='Enter first name'
                                    required
                                    isInvalid={!!error.firstname}
                                />
                                <Form.Control.Feedback type="invalid">{error.firstname}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId='formLastName'>
                                <Form.Label className='text-custom my-2'>Last Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type='text'
                                    name='lastname'
                                    value={formData.lastname}
                                    onChange={handleInputChange}
                                    placeholder='Enter last name'
                                    required
                                    isInvalid={!!error.lastname}
                                />
                                <Form.Control.Feedback type="invalid">{error.lastname}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col md={6} xs={12}>
                            <Form.Group controlId='formEmail'>
                                <Form.Label className='text-custom my-2'>Email Address*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    className='py-2'
                                    type="email"
                                    placeholder="Enter your email"
                                    name="useremail"
                                    value={formData.useremail}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!error.useremail}
                                />
                                <Form.Control.Feedback type="invalid">{error.useremail}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                            <Form.Group className='custome-select-country'>
                                <Form.Label className='text-custom my-2'>Country</Form.Label>
                                <CountrySelect
                                    inputClassName="py-1 border-0"
                                    placeHolder="Select Country"
                                    value={formData.country}
                                    onChange={(country) => setFormData({ ...formData, country: country.name })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <Form.Group controlId='formPassword' className='mb-3'>
                                <Form.Label className='text-custom my-2'>Password*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type='password'
                                    name='password'
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    placeholder='Enter password'
                                    required
                                    isInvalid={!!error.password}
                                />
                                <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                            <Form.Group controlId='formConfirmPassword'>
                                <Form.Label className='text-custom my-2'>Confirm Password*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type='password'
                                    name='confirmPassword'
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    placeholder='Confirm password'
                                    required
                                    isInvalid={!!error.confirmPassword}
                                />
                                <Form.Control.Feedback type="invalid">{error.confirmPassword}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Group className='mb-3'>
                                <Form.Label className='text-custom my-2'>Phone Number</Form.Label>
                                <PhoneInput
                                    inputClass='custome-phone py-4'
                                    country={'all'}
                                    placeholder='Enter your phone number'
                                    value={formData.phone}
                                    onChange={(phone) => setFormData({ ...formData, phone: phone })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-between pt-2">
                        <Button onClick={handleCancel} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                        <Button type="button" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' onClick={handleFormSubmit} disabled={loading}>
                            {loading ? 'Creating...' : 'Create'}
                        </Button>
                    </div>
                </Form>
            </Container>
        </Modal>
    );
};

export default AddUser;
