import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CustomCard = ({ data, loading }) => {
    const defaultCards = [
        { label: 'Loading', value: 'Loading' },
        { label: 'Loading', value: 'Loading' },
        { label: 'Loading', value: 'Loading' },
        { label: 'Loading', value: 'Loading' }
    ];

    const cardsToDisplay = loading ? defaultCards : data;

    return (
        <Container fluid>
        <Row className="gap-3 px-5">
            {cardsToDisplay.map((item, index) => (
                <Col
                    key={index}
                    className={` rounded-4 p-3 mt-0 `}
                    style={{ background: "var(--color1)", height: "100px" }}
                >
                    <p className="mb-1 small" style={{ color: "var(--color4)" }}>
                        {loading ? (
                            <Skeleton
                                width={80}
                                height={20}
                                baseColor="#ffe521"
                                highlightColor="#fff899"
                            />
                        ) : (
                            item?.label
                        )}
                    </p>
                    {loading ? (
                        <div className="ps-2">
                            <div className="loader-small"></div>
                        </div>
                    ) : (
                        <h4 style={{ color: "var(--color4)" }}>{item?.value}</h4>
                    )}
                </Col>
            ))}
        </Row></Container>
    );
};

export default CustomCard;
