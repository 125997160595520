import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { startOfMonth, endOfMonth } from 'date-fns';

import { useEffect, useState } from 'react';
import NotFound from './views/NotFound';
import Login from './views/Login';
import DashboardLayout from './components/layouts/sidebar/Layout';
import Dashboard from './views/user/Dashboard';
import Metrics from './views/user/Metrics';
import Creatives from './views/user/Creatives';
import Site from './views/user/Site';
import Geo from './views/user/Geo';
import { AuthVerify, GetUserRoles } from './utils/auth.utils';
import AdminDashboard from './views/admin/Dashboard';
import CompanyDetails from './views/admin/CompanyDetails';
import OvierviewPerDay from './views/user/OvierviewPerDay';
import UserManagement from './views/admin/UserManagement';
import CustomStartPage from './CustomStartPage';
import CustomEndpage from './CustomEndPage';
import PdfDashboard from './pdfComponents/PdfDashboard';
import PdfOvierviewPerDay from './pdfComponents/PdfOvierviewPerDay';
import PdfMetrics from './pdfComponents/PdfMetrics';
import { Modal, ProgressBar, Spinner } from 'react-bootstrap';
import { useModal } from './utils/Modal';
import PdfCreatives from './pdfComponents/PdfCreatives';
import PdfGeo from './pdfComponents/PdfGeo';
import PdfSite from './pdfComponents/PdfSite';

const Navigation = () => {
    const [selectedRange, setSelectedRange] = useState([startOfMonth(new Date()), endOfMonth(new Date())]);
    const [progress, setProgress] = useState(5);
    const verify = AuthVerify();
    const navigate = useNavigate();
    const UserRole = GetUserRoles()
    const location = useLocation();
    const [showImage, setShowImage] = useState(false)
    const { showModal, setShowModal } = useModal();

    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        if (location.pathname === '/overview' || location.pathname === '/') {
            if (verify) {
                UserRole === 'admin'
                    ? navigate('/dashboard')
                    : navigate('/overview');
            } else {
                navigate('/login');
            }
        }
    }, [verify, UserRole, navigate, location.pathname]);

    return (
        <>
            <Modal show={showModal} centered backdrop="static" keyboard={false} backdropClassName="pulse-backdrop" className='pdf-drop'>
                <Modal.Body className="text-center">
                    <Spinner animation="border" role="status" className='loader-custom' />
                    <div className="mt-3">Your PDF is being created. This may take a few moments. Please do not close, switch tabs or refresh the window.</div>
                    <ProgressBar now={progress} label={`${Math.round(progress)}%`} className="mt-3 custom-progress" style={{ color: '#09332c' }} />
                </Modal.Body>
            </Modal>
            <Routes>
                <Route path='/*' element={<NotFound />} />
                <Route path='/login' element={<Login />} />
                <Route element={<DashboardLayout setSelectedRange={setSelectedRange} selectedRange={selectedRange} showImage={showImage} setShowImage={setShowImage} setProgress={setProgress} />}>
                    {UserRole === "admin" && (
                        <>
                            <Route path='/dashboard' element={<AdminDashboard />} />
                            <Route path='/user-management' element={<UserManagement />} />
                            <Route path='/dashboard/view-company/:companyId' element={<CompanyDetails />} />

                        </>
                    )}
                    {(UserRole === "user") && (
                        <>
                            <Route path='/overview' element={<Dashboard selectedRange={selectedRange} showImage={showImage} />} />
                            {loggedInUser?.overviewPerDay &&
                                <Route path='/overview-per-day' element={<OvierviewPerDay selectedRange={selectedRange} showImage={showImage} />} />
                            }
                            <Route path='/metrics' element={<Metrics selectedRange={selectedRange} showImage={showImage} />} />
                            <Route path='/creatives' element={<Creatives selectedRange={selectedRange} showImage={showImage} />} />
                            {loggedInUser?.siteIDShow &&
                                <Route path="/placements" element={<Site selectedRange={selectedRange} showImage={showImage} />} />
                            }
                            {loggedInUser?.showgeo &&
                                <Route path="/geo" element={<Geo selectedRange={selectedRange} showImage={showImage} />} />
                            }
                        </>
                    )}

                </Route>
                {/* {showModal && */}
                <>
                    <Route path='/overview-pdf' element={<PdfDashboard selectedRange={selectedRange} showImage={showImage} />} />
                    <Route path='/overview-per-day-pdf' element={<PdfOvierviewPerDay selectedRange={selectedRange} showImage={showImage} />} />
                    <Route path='/metrics-pdf' element={<PdfMetrics selectedRange={selectedRange} showImage={showImage} />} />
                    <Route path='/creatives-pdf' element={<PdfCreatives selectedRange={selectedRange} showImage={showImage} />} />
                    <Route path="/placements-pdf" element={<PdfSite selectedRange={selectedRange} showImage={showImage} />} />
                    <Route path="/locations-pdf" element={<PdfGeo selectedRange={selectedRange} showImage={showImage} />} />
                    <Route path='/start-pdf' element={<CustomStartPage selectedRange={selectedRange} showImage={showImage} />} />
                    <Route path='/end-pdf' element={<CustomEndpage />} />
                </>
                {/* } */}

            </Routes>
        </>

    );
};

export default Navigation;
