import { Image, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import logo from "../../../assets/logo/sidebar.png";
import Drop from "../../../assets/Drop.png";
import { SidebarMenus } from '../../shared/MenuItems';
import { GetUserRoles } from '../../../utils/auth.utils';
import { useEffect, useState } from 'react';
const Sidebar = () => {
  const UserRole = GetUserRoles();
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
  const isVisible = (visibleOn) => {
    return visibleOn.some(role => role === UserRole);
  };
  useEffect(() => {
    const handleStorageChange = () => {
      setUser(JSON.parse(localStorage.getItem("user")) || {});
    };

    window.addEventListener('user-update', handleStorageChange);
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('user-update', handleStorageChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  return (
    <div className='d-flex flex-column min-vh-100 px-2' style={{ minWidth: '180px', }}>
      <Nav className="flex-column flex-grow-1">
        <div className='d-flex align-items-center'>
          <Link className="d-none d-lg-block py-4 mb-4">
            <Image
              src={logo}
              alt='Logo'
              className='w-100'
            />
          </Link>
        </div>

        <div>
          {SidebarMenus.slice(0, 2).map((link) => (
            (link.label !== "Overview per days" || user?.overviewPerDay) &&
            isVisible(link.visibleOn) && (
              <NavLink
                className="py-2 px-4  mb-0 d-flex small align-items-center text-decoration-none rounded-3"
                key={link.path}
                to={link.path}
                style={({ isActive }) => ({
                  color: 'var(--color2)',
                  fontWeight: isActive ? 'bold' : '',
                  background: isActive ? 'var(--color1)' : 'transparent',
                })}
              >
                <div className="mb-1 me-2">
                  <Image style={{ height: "20px" }} src={link.icon} alt={link.label} />
                </div>
                <p className='mb-0'>{link.label}</p>
              </NavLink>
            )
          ))}

          {UserRole !== 'admin' && (
            <p style={{ color: "var(--color4)" }} className="py-3 px-4 my-3 small text-uppercase">
              By category
            </p>
          )}
          {SidebarMenus.slice(2, 10).map((link) => (
            (link.label !== "Placements" || user?.siteIDShow) &&
            (link.label !== "Locations" || user?.showgeo) &&
            isVisible(link.visibleOn) && (
              <NavLink
                className="py-2 px-4 mb-0 d-flex small align-items-center text-decoration-none rounded-3"
                key={link.path}
                to={link.path}
                style={({ isActive }) => ({
                  color: 'var(--color2)',
                  fontWeight: isActive ? 'bold' : '',
                  background: isActive ? 'var(--color1)' : 'transparent',
                })}
              >
                <div className="mb-1 me-2">
                  <Image style={{ height: "20px" }} src={link.icon} alt={link.label} />
                </div>
                <p className='mb-0'>{link.label}</p>
              </NavLink>
            )
          ))}

        </div>
      </Nav>

      <div className='mt-auto mb-1'>
        <Image src={Drop} alt="Drops" className='w-100' />
      </div>
    </div>
  );
};

export default Sidebar;
