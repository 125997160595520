import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';

const CustomBar = ({ data, height, loading, dot }) => {
    const eventKeys = data && data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'name') : [];

    const colors = [
        "var(--color2)", "var(--color1)", "var(--color3)",
        "var(--color4)"
    ];

    return (
        <>
            {loading ? (
                <Skeleton height={height || 590} width="100%" />
            ) : data && data.length > 0 ? (
                <ResponsiveContainer width="100%" height={height || 620}>
                    <ComposedChart
                        style={{
                            fontFamily: 'Sora'
                        }}
                        data={data}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 90,
                            left: -20,
                        }}>
                        <CartesianGrid stroke="#f5f5f5" vertical={false} horizontal={true} />

                        <XAxis
                            dataKey="name"
                            interval={0}
                            padding={{ left: 10, right: 10 }}
                            // tick={{ fontSize: 8, angle: -60, textAnchor: 'end' }}
                            tick={{
                                fontSize: 16,
                                fill: '#718096',
                                fontFamily: 'Sora',
                                angle: -60,
                                textAnchor: 'end',
                                fontWeight: '400'
                            }}
                            ticks={data.length > 30 ? data.filter((_, index) => index % Math.ceil(data.length / 30) === 0).map(item => item.name) : undefined}
                        />

                        <YAxis yAxisId="left" axisLine={false} orientation="left" tickFormatter={(value) => Math.round(value)} />

                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            axisLine={false}
                            tickFormatter={(value) => Math.round(value)}
                        />


                        <Tooltip contentStyle={{ fontSize: '10px' }} />
                        <Legend
                            layout="horizontal"
                            align="right"
                            verticalAlign="top"
                            fontSize="10px"
                            wrapperStyle={{ marginTop: -43, paddingBottom: 10 }}
                        />

                        {eventKeys.length > 0 && (
                            <Bar
                                key={`bar-${eventKeys[0]}`}
                                dataKey={eventKeys[0]}
                                barSize={40}
                                fill={colors[0]}
                                yAxisId="left"
                            />
                        )}

                        {eventKeys.slice(1).map((eventKey, index) => (
                            <Line
                                key={`line-${eventKey}`}
                                type="monotone"
                                dataKey={eventKey}
                                stroke={colors[(index + 1) % colors.length]}
                                strokeWidth={3}
                                yAxisId="right"
                                dot={false}
                            />
                        ))}
                    </ComposedChart>
                </ResponsiveContainer>
            ) : (
                <div className="text-center" style={{ marginTop: '20px', fontSize: '16px', color: '#666', height: 470 }}>
                    No Data Available
                </div>
            )}
        </>
    );
};

export default CustomBar;
