import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, YAxis } from 'recharts';

function DayDistributionChart({ data, loading }) {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const processedData = data?.map(entry => ({
        name: entry?.name,
        value: Number(entry?.value)
    })) || [];

    // Find the maximum value in the processedData
    const maxValue = Math.max(...processedData.map(entry => entry.value));

    const renderTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { name, value } = payload[0]?.payload;
            return (
                <div className="custom-tooltip py-2 ps-3 pe-5" style={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '5px' }}>
                    <p className="mb-0">{name}</p>
                    <p className="mb-0">{value}%</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            {loading ? (
                <Skeleton height={430} width="100%" />
            ) : processedData.length > 0 ? (
                <ResponsiveContainer width="100%" height={430}>
                    <BarChart data={processedData}>
                        <CartesianGrid vertical={false} horizontal={false} />
                        <XAxis
                            dataKey="name"
                            tick={{
                                fontSize: 16,
                                fill: '#718096',
                                fontFamily: 'Sora',
                                fontWeight: '400'
                            }}
                        />

                        <YAxis hide tickCount={10} />
                        <Tooltip content={renderTooltip} />
                        <Bar
                            dataKey="value"
                            background={{ fill: "rgba(128, 128, 128, 0.2)", radius: 10 }}
                            onMouseOver={(data, index) => setHoveredIndex(index)}
                            onMouseOut={() => setHoveredIndex(null)}
                        >
                            {processedData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={hoveredIndex === index ? '#ffe521' : (entry.value === maxValue ? '#ffe521' : '#007755')}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <div className="text-center" style={{ marginTop: '20px', fontSize: '16px', color: '#666' }}>
                    No Data
                </div>
            )}
        </>
    );
}

export default DayDistributionChart;
