import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container, Image } from 'react-bootstrap';
// import arrow from "./assets/Vector.svg";
import pdfStartImage from './assets/Intro.png';

const CustomStartPage = ({ selectedRange, showImage }) => {
  const [user,] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (selectedRange && selectedRange?.length === 2) {
      const [startDate, endDate] = selectedRange;
      const formatLocalDate = (date) => {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
      };
      const formattedStartDate = formatLocalDate(startDate);
      const formattedEndDate = formatLocalDate(endDate);
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
  }, [selectedRange]);
  return (
    <div
      id='pdf'
      className="pdfstart d-flex justify-content-between align-items-center  flex-column py-4"
      style={{
        backgroundImage: `url(${pdfStartImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        width: '635px', // Approx A4 width in pixels
        height: '900px', // Approx A4 height in pixels
      }}
    >
      <div className="d-flex align-items-center gap-5 mb-4">
        <div style={{ width: "100%" }}>
          <img src={"https://res.cloudinary.com/djmk3gpgr/image/upload/v1730471190/qmwsefrmljgbcpfuwaqh.png"} alt="logo" width={100} height={100} className="img-fluid" />
        </div>

      </div>
      <Container className='px-0'>
        <div className='d-flex align-items-center justify-content-center gap-5 flex-column'>
          <div>
            <h1 style={{ color: "#09332C", fontFamily: "Sora", fontSize: '96px', lineHeight: '91.2px', letterSpacing: '0.03em' }} className=''>Reporting</h1>
            <h1 style={{ color: "#007755", fontFamily: "Sora", fontSize: '96px', lineHeight: '91.2px', letterSpacing: '0.03em' }} className='mb-4 pt-2'>Campaign</h1>
          </div>
          <div className='pt-4'>
            <h5 style={{ color: '#09332C', fontFamily: "Sora", fontSize: '14px', lineHeight: '12.79px', fontWeight: '600', letterSpacing: '0.03em' }} className='text-center'><strong>Period Of Time</strong> </h5>
            <div className='d-flex align-items-center justify-content-center gap-2'>
              <h5 style={{ color: '#09332C', fontFamily: "Work Sans", fontSize: '14px', lineHeight: '12.79px', fontWeight: '400', letterSpacing: '0.05em' }} className='mb-0'>{moment(startDate).format("MMM Do YYYY")}</h5>
              <Image src="https://res.cloudinary.com/djmk3gpgr/image/upload/v1730703885/arrow_left.png" />
              <h5 style={{ color: '#09332C', fontFamily: "Work Sans", fontSize: '14px', lineHeight: '12.79px', fontWeight: '400', letterSpacing: '0.05em' }} className='mb-0'>{moment(endDate).format("MMM Do YYYY")}</h5>
            </div>
          </div>
        </div>
      </Container>
      <div className="card border-0 shadow p-2" style={{ background: '#09332C' }}>
        <img
          src={user?.logo}
          alt="Company Logo"
          style={{ width: "40px", objectFit: "scale-down" }}
        />
      </div>
    </div>
  );
};
export default CustomStartPage; 