import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Bar, BarChart, LabelList, Rectangle, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

const MobilePodium = ({ data, loading }) => {
    const [top3Data, setTop3Data] = useState([data]);

    useEffect(() => {
        if (data && data.length > 0) {
            const sortedData = [...data].sort((a, b) => b.value - a.value);
            const top3 = sortedData.slice(0, 3);
            const processedData = top3.map((item, index) => ({
                ...item,
                image: item.image || 'https://placehold.co/600x400', // Use image from backend, fallback to placeholder
                fill: getBarFillColor(index) // Assign color based on the index
            }));

            setTop3Data(processedData);
        }
    }, [data]);

    const barSize = 120;

    const getBarFillColor = (index) => {
        switch (index) {
            case 0:
                return "var(--color1)";
            case 1:
                return "var(--color2)";
            case 2:
                return "var(--color3)";
            default:
                return "#EEEEEE";
        }
    };

    const renderCustomLabel = ({ x, y, value, index }) => {
        const adjustedX = x - barSize / 4;
        const adjustedY = y - 140;
        return (
            <foreignObject x={adjustedX} y={adjustedY} width={barSize} height={100} style={{ overflow: 'visible' }}>
                <div className='d-flex flex-column justify-content-center align-items-center ms-5'>
                    <div className='image-container'>
                        <img
                            src={top3Data[index].image}
                            alt={top3Data[index].name}
                            className="rounded-3 small-image"
                            style={{ width: barSize, height: '100px', marginBottom: '10px', objectFit: 'contain' }}
                        />
                        <img
                            src={top3Data[index].image}
                            alt={top3Data[index].name}
                            data-html2canvas-ignore="true"
                            className="rounded-3 large-image no-capture "
                        />

                    </div>
                    <div className='text-center w-100'>
                        <h5 data-html2canvas-ignore="true" className="fs-12 mb-0 wrap-text">{top3Data[index].name}</h5>
                    </div>
                </div>
            </foreignObject>
        );
    };

    const renderTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { name, value } = payload[0]?.payload;
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <p className="mb-0">{name}</p>
                    <p className="mb-0">{value}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            {loading ? (
                <Skeleton height={430} width="100%" />
            ) : (
                top3Data?.length > 0 ? (
                    <ResponsiveContainer width="100%" height={430}>
                        <BarChart
                            data={top3Data}
                            margin={{ top: 150, right: 30, bottom: 0, left: 0 }}
                        >
                            <XAxis
                                dataKey="value"
                                interval={0}
                                axisLine={false}
                                tickLine={false}
                                tickFormatter={(value, index) => `Top ${index + 1}`}
                                tick={{
                                    fontSize: 14,
                                    fill: '#AAAAAA',
                                    fontFamily: 'Sora',
                                    fontWeight: '400'
                                }}
                            />
                            <Bar
                                dataKey="value"
                                barSize={barSize}
                                radius={[10, 10, 0, 0]}
                                fill={({ payload }) => payload.fill}
                            >
                                <LabelList content={renderCustomLabel} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <p>No data available</p>
                )
            )}
        </div>
    );
};

export default MobilePodium;
