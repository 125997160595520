
export function AuthHeader() {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser && loggedInUser.token
    ? { "x-access-token": loggedInUser.token }
    : {};
}
export const GetUserRoles = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser?.roles || [];
};
export const GetCompanyLogo = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser?.logo || '';
};
export const AuthName = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser?.name || "User";
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const GetUserName = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  if (loggedInUser?.firstname && loggedInUser?.lastname) {
    const fullName = capitalizeFirstLetter(loggedInUser.firstname) + " " + capitalizeFirstLetter(loggedInUser.lastname);
    return fullName;
  }

  return null;
};

export const AuthVerify = () => {
  const loggedInUser = JSON.parse(localStorage?.getItem("user"));
  return loggedInUser ? true : false;
};
