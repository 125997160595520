import { GetApiData } from "../../utils/http-client";
// const companyId = localStorage.getItem('selectedCompany');
export const getStatsData = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/${companyId}/${query}`, 'GET', null, true);
}
export const getCtrStatsData = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/ctr/${companyId}/${query}`, 'GET', null, true);
}
export const getChartData = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/chart/${companyId}/${query}`, 'GET', null, true);
}
export const getChartCountry = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/countryDistribution/${companyId}/${query}`, 'GET', null, true);
}
export const getChartAttribute = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/attributeChart/${companyId}/${query}`, 'GET', null, true);
}
export const getDayChartData = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/dayChart/${companyId}/${query}`, 'GET', null, true);
}
export const getChartDataPie = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/pieChart/${companyId}/${query}`, 'GET', null, true);
}
export const getChartDataPiePerDay = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/pieChartPerDay/${companyId}/${query}`, 'GET', null, true);
}
export const getCategoryChartDataPie = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/pieCategoryChart/${companyId}/${query}`, 'GET', null, true);
}
export const getTopChart = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/getTop/${companyId}/${query}`, 'GET', null, true);
}
export const getCityChart = function (startDate, endDate, companyId, selectedMap) {
    const query = `?startDate=${startDate}&endDate=${endDate}&country=${selectedMap}`;
    return GetApiData(`/stats/cityData/${companyId}/${query}`, 'GET', null, true);
}
export const getCountryChart = function (startDate, endDate, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/countryData/${companyId}/${query}`, 'GET', null, true);
}
export const getTableData = function (startDate, endDate, page, limit, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    return GetApiData(`/stats/table/${companyId}/${query}`, 'GET', null, true);
};
export const getTableCsv = (startDate, endDate, companyId) => {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/tablexls/${companyId}${query}`, 'GET', null, true, '', 'blob');
};
export const getTablePerDayCsv = (startDate, endDate, companyId) => {
    const query = `?startDate=${startDate}&endDate=${endDate}`;
    return GetApiData(`/stats/tablePerDayxls/${companyId}${query}`, 'GET', null, true, '', 'blob');
};
export const getTableDataPerDay = function (startDate, endDate, page, limit, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    return GetApiData(`/stats/tablePerDay/${companyId}/${query}`, 'GET', null, true);
};
export const getCategoryTable = function (startDate, endDate, page, limit, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    return GetApiData(`/stats/categoryTable/${companyId}/${query}`, 'GET', null, true);
}
export const getCreativeTableData = function (startDate, endDate, page, limit, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    return GetApiData(`/stats/creativeTable/${companyId}/${query}`, 'GET', null, true);
}
export const getStateTableData = function (startDate, endDate, page, limit, companyId) {
    const query = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    return GetApiData(`/stats/stateData/${companyId}/${query}`, 'GET', null, true);
}
export const getCompanyMapCountry = function (companyId) {
    return GetApiData(`/company/mapshow/${companyId}`, 'GET', null, true);
}
