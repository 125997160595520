import React, { useEffect, useState } from 'react';
import Bar from '../components/charts/Bar';
import Pie from '../components/charts/Pie';
import ChartHeading from '../components/shared/ChartHeading';
import DashboardTable from '../components/shared/Table';
import TableHeading from '../components/shared/TableHeading';
import Pagination from '../components/shared/TablePagination';
import { Col, Container, Row } from 'react-bootstrap';
import { getChartData, getChartDataPie, getStatsData, getTableData } from '../services/adminService/adminService';
import { DashboardColors } from '../utils/Static';
import PdfHeader from '../components/shared/PdfHeader';
import PdfFooter from '../components/shared/PdfFooter';
import CustomCard from './PdfCustomCard';
const PdfDashboard = ({ selectedRange, showImage }) => {
    console.log("Shiw", showImage)
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState(true);
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [appIdShow, setAppId] = useState();
    const [chartData, setChartData] = useState(null);
    const [pieChartData, setPieChartData] = useState(null);
    const [stats, setStats] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [companyId, setCompanyId] = useState(localStorage.getItem('selectedCompany'));

    const DashboardtableHeadings = [
        "Rank",
        "Campaign",
        "App ID",
        "Week",
        "Country",
        tableData?.data?.length > 0 ? Object?.keys(tableData?.data[0])[4] : "FTD’s (unique)",
        ...(tableData?.tabsShown?.includes("overview") ? ["Budget"] : [])
    ];

    const DashboardtableHeadings2 = [
        "Rank",
        "Campaign",
        "Week",
        "Country",
        tableData?.data?.length > 0 ? Object?.keys(tableData?.data[0])[4] : "FTD’s (unique)",
        ...(tableData?.tabsShown?.includes("overview") ? ["Budget"] : [])
    ];

    const tableHeadings = () => {
        if (appIdShow) return DashboardtableHeadings;
        return DashboardtableHeadings2;
    };

    const tableBody = tableData?.data?.map((data) => {
        const row = [
            data?.rank,
            data?.campaign,
            ...(appIdShow ? [data?.appId] : []),
            data?.week,
            data?.countryCode,
            ...(appIdShow ? [Object.values(data)[4]] : [Object.values(data)[4]]),
            ...(tableData?.tabsShown?.includes("overview")
                ? [`${new Intl.NumberFormat('fr-FR').format(data?.Budget)} ${tableData?.currency || ''}`]
                : [])];
        return row.filter(value => value != null);
    }) || [];
    const dashboardCards = stats?.dynamicResults
        ? Object.entries(stats.dynamicResults).map(([label, value], index) => ({
            label: label,
            value: index === 0
                ? `${new Intl.NumberFormat('fr-FR').format(value)} ${stats?.currency || ''}`
                : `${new Intl.NumberFormat('fr-FR').format(value)}`,
        }))
        : [];

    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getStatsData(formattedStartDate, formattedEndDate, companyId);
            setStats(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const getChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setChartLoading(true);
            const response = await getChartData(formattedStartDate, formattedEndDate, companyId);
            setChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setChartLoading(false);
        } finally {
            setChartLoading(false);
        }
    }
    const getPieChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieChartLoading(true);
            const response = await getChartDataPie(formattedStartDate, formattedEndDate, companyId);
            setPieChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getTable = async (page, formattedStartDate, formattedEndDate) => {
        try {
            setTableLoading(true);
            const response = await getTableData(formattedStartDate, formattedEndDate, page, 7, companyId);
            console.log("Table data dash", response?.data?.data)
            setTableData(response?.data?.data);
            setTotalPages(response?.data?.data?.totalPages);
            setAppId(response?.data?.data?.appIdEnabled)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setTableLoading(false);
        } finally {
            setTableLoading(false);
        }
    }
    useEffect(() => {
        console.log("Company Id again", companyId)

        const handleCompanyChange = () => {
            setCompanyId(localStorage.getItem('selectedCompany'));
        };
        window.addEventListener('user-update', handleCompanyChange);

        return () => {
            window.removeEventListener('user-update', handleCompanyChange);
        };
    }, [companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getStats(formattedStartDate, formattedEndDate);
            getChart(formattedStartDate, formattedEndDate);
            getPieChart(formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, companyId]);

    useEffect(() => {
        console.log("Message")
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getTable(currentPage, formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, currentPage, companyId]);
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div id="pdf" className='pdf-size'>
            <div style={{
                backgroundImage: showImage
                    ? "url('https://res.cloudinary.com/djmk3gpgr/image/upload/v1730396033/skhbjvc4cy1zxtzudjvm.png')"
                    : "none",
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                backgroundPosition: 'top center'
            }}
            >
                <PdfHeader showImage={showImage} />
                <Container className={`my-5 ${showImage ? 'px-5' : ''}`} fluid>
                    <CustomCard data={dashboardCards} loading={loading} />
                    <Row>
                        <Col xs={8}>
                            <div className='bg-white rounded-4 p-4 my-4'>
                                <ChartHeading heading={"Performances"} />
                                <Bar data={chartData} loading={chartLoading} />
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='bg-white rounded-4 p-4 my-4'>
                                <ChartHeading heading={"Performances"} />
                                <div className=''>
                                    <Pie textWidth={"80%"} height={400} width={400} outerRadius={"60%"} innerRadius={"40%"} COLORS={DashboardColors} data={pieChartData} loading={pieChartLoading} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={`${showImage ? 'pb-3' : ''}`}>
                            <div className={`py-5  px-3  bg-white rounded-4 m-1 h-100 ${showImage ? '' : ''}`}>
                                <div className='d-flex justify-content-between align-items-center mb-4'>
                                    <TableHeading heading={"By Campaign"} />
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onNext={handleNext}
                                        onPrevious={handlePrevious}
                                        loading={tableLoading}
                                    />
                                </div>
                                <DashboardTable
                                    tableBody={tableBody}
                                    tableHeadings={tableHeadings()}
                                    loading={tableLoading}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container >
            </div>
            <PdfFooter showImage={showImage} />
        </div>
    );
};
export default PdfDashboard;