import DashboardTableSkeleton from '../../components/skelton/DashboardTableSkeleton';
import React from 'react';
import { Table } from 'react-bootstrap';

const DashboardTable = ({ tableBody, tableHeadings, loading, count }) => {
    return (
        <>
            {loading ? (
                <DashboardTableSkeleton rowCount={10} columnCount={tableHeadings?.length} />
            ) : tableBody && tableBody?.length > 0 ? (
                <div className="py-3 rounded-3 table-wrapper">
                    <Table hover responsive>
                        <thead>
                            <tr>
                                {tableHeadings?.map((th, idx) => (
                                    <th className={`small table-head ${idx === 0 ? 'sticky-column' : ''}`} style={{ color: "#B6B6B6" }} key={idx}>{th}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableBody?.map((row, i) => (
                                <tr key={i}>
                                    {row.map((cell, j) => (
                                        <td key={j} className={j === 0 ? 'sticky-column' : ''} style={{ color: "#718096" }}>
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ) : (
                <div className="text-center p-3" style={{ color: "#718096" }}>
                    No Data Available
                </div>
            )}
        </>
    );
};

export default DashboardTable;
