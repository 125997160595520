import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GetUserRoles } from '../../utils/auth.utils';
import { SidebarMenus } from '../shared/MenuItems';
import { Image } from 'react-bootstrap';

const BottomNavigation = () => {
    const UserRole = GetUserRoles();
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});

    const isVisible = (visibleOn) => {
        return visibleOn.some(role => role === UserRole);
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setUser(JSON.parse(localStorage.getItem("user")) || {});
        };

        window.addEventListener('user-update', handleStorageChange);
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('user-update', handleStorageChange);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <div className="d-lg-none d-flex justify-content-around align-items-center px-2 pt-1 pb-4 bg-white card flex-row border-0 shadow" style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: '99999999' }}>
            {SidebarMenus.slice(0, 10).map((link) => (
                (link.label !== "Overview per days" || user?.overviewPerDay) &&
                (link.label !== "Placements" || user?.siteIDShow) &&
                (link.label !== "Locations" || user?.showgeo) &&
                isVisible(link.visibleOn) && (
                    <NavLink
                        key={link.path}
                        to={link.path}
                        className={({ isActive }) => isActive ? "active" : ""}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textDecoration: 'none',
                        }}
                    >
                        <div className="nav-icon">
                            <Image src={link.icon} alt={link.label} style={{ width: 24, height: 24 }} />
                        </div>
                        <span style={{ fontSize: '6px', textDecoration: 'none', color: '#007755', fontWeight: '600' }}>
                            {link.label}
                        </span>
                    </NavLink>
                )
            ))}
        </div>
    );
};

export default BottomNavigation;
