import React, { useCallback, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const EventSelection = ({ formData, setFormData, additional }) => {
    console.log("Form Data Events", formData.eventsShow);
    const eventOptions = useMemo(() => (
        additional?.eventNames?.map(event => ({ value: event, label: event })) || []
    ), [additional]);

    const handleSelectChange = useCallback((selectedOptions, name) => {
        const limitedSelection = selectedOptions ? selectedOptions.slice(0, 2) : [];
        setFormData(prev => ({
            ...prev,
            [name]: limitedSelection.map(option => option.value)
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row className="mt-3">
            <Col xs={12}>
                <Form.Group controlId="formEvent">
                    <Form.Label className="text-secondary">Select Events to Show in Graphs (max 2)</Form.Label>
                    <Select
                        isMulti
                        options={eventOptions}
                        value={eventOptions.filter(option => formData?.eventsShow?.includes(option?.value))}
                        onChange={selectedOptions => handleSelectChange(selectedOptions, 'eventsShow')}
                        closeMenuOnSelect={false}
                        isOptionDisabled={() => formData?.eventsShow?.length >= 2}
                        isOptionSelected={(option) => formData?.eventsShow?.includes(option.value)}
                    />
                </Form.Group>
            </Col>
        </Row>
    );
};

export default EventSelection;
