import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
const CustomCardMobile = ({ cardsToDisplay, loading }) => {
    const isFourItems = cardsToDisplay.length === 4;
    const halfIndex = isFourItems ? Math.ceil(cardsToDisplay.length / 2) : cardsToDisplay.length;
    const firstRowItems = cardsToDisplay.slice(0, halfIndex);
    const secondRowItems = isFourItems ? cardsToDisplay.slice(halfIndex) : [];
    return (
        <div>
            <Row className="px-2 mt-2 gap-3">
                {firstRowItems.map((item, index) => (
                    <Col
                        key={index}
                        className="rounded-4 p-3 d-flex gap-1"
                        style={{ background: "var(--color1)", }}
                    >
                        <div>
                            <p className="mb-1 small" style={{ color: "var(--color4)", fontSize: '12px' }}>
                                {loading ? (
                                    <Skeleton
                                        width={80}
                                        height={20}
                                        baseColor="#ffe521"
                                        highlightColor="#fff899"
                                    />
                                ) : (
                                    item?.label
                                )}
                            </p>
                            {loading ? (
                                <div className="ps-2">
                                    <div className="loader-small"></div>
                                </div>
                            ) : (
                                <h4 style={{ color: "var(--color4)", fontSize: '16px' }}>{item?.value}</h4>
                            )}
                        </div>
                    </Col>
                ))}
            </Row>

            {isFourItems && (
                <Row className="px-2 mt-4 gap-3">
                    {secondRowItems.map((item, index) => (
                        <Col
                            key={index}
                            className="rounded-4 p-3"
                            style={{ background: "var(--color1)" }}
                        >
                            <div>
                                <p className="mb-1 small" style={{ color: "var(--color4)", fontSize: '12px' }}>
                                    {loading ? (
                                        <Skeleton
                                            width={80}
                                            height={20}
                                            baseColor="#ffe521"
                                            highlightColor="#fff899"
                                        />
                                    ) : (
                                        item?.label
                                    )}
                                </p>
                                {loading ? (
                                    <div className="ps-2">
                                        <div className="loader-small"></div>
                                    </div>
                                ) : (
                                    <h4 style={{ color: "var(--color4)", fontSize: '16px' }}>{item?.value}</h4>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    )
}

export default CustomCardMobile
