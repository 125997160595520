import { Image, Offcanvas } from 'react-bootstrap'
import { FaUser } from 'react-icons/fa'
import logo from '../../../assets/logo/Logo.svg';

import { NavLink } from 'react-router-dom'
import { SidebarMenus } from '../../shared/MenuItems';
import { GetUserRoles } from '../../../utils/auth.utils';
import GeneratePdf from '../../../Pdf';
import { FiUser } from 'react-icons/fi';
import { useAuth } from '../../../utils/AuthContext';
const OffCanvas = ({ showSidebar, handleCloseSidebar }) => {
    const UserRole = GetUserRoles()
    const { logout } = useAuth();
    const isVisible = (visibleOn) => {
        return visibleOn.some(role => role === UserRole);
    };
    return (
        <Offcanvas style={{ width: "300px" }} show={showSidebar} onHide={handleCloseSidebar} placement="start">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='fw-semibold' style={{ color: "var(--primary-color)" }}>                    <Image src={logo} alt="Logo" style={{ height: '32px' }} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0'>
                {SidebarMenus.map((link) => (
                    isVisible(link.visibleOn) && (
                        <NavLink
                            className="py-3  px-4 d-flex align-items-center"
                            key={link.path}
                            onClick={handleCloseSidebar}
                            to={link.path}
                            style={({ isActive }) => ({
                                color: 'var(--color2)',
                                fontWeight: isActive ? 'bold' : '',
                                background: isActive
                                    ? '#dbfff0 '
                                    : 'transparent',
                                borderTopRightRadius: '30px',
                                borderBottomRightRadius: '30px',
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: 'center',
                            })}
                        >    <div className='mb-1' style={{ marginRight: '10px' }}>
                                <Image src={link.icon} alt={link.label} />
                            </div>
                            {link.label}
                        </NavLink>
                    )
                ))}
                <NavLink
                    onClick={handleCloseSidebar}

                    className="py-3  px-4 d-flex align-items-center text-decoration-none"
                    to="mailto:contact@kypi.io"
                    style={{
                        color: 'var(--color2)'
                    }}
                >
                    <div className='mb-1 me-3' style={{ marginRight: '10px' }}>
                        <FiUser />
                    </div>
                    Contact
                </NavLink>
                <NavLink
                    onClick={logout}
                    className="py-3  px-4 d-flex align-items-center text-decoration-none"
                    style={{
                        color: 'var(--color2)'
                    }}
                >
                    <div className='mb-1 me-3' style={{ marginRight: '10px' }}>
                        <FiUser />
                    </div>
                    Logout
                </NavLink>
                <NavLink
                    onClick={handleCloseSidebar}

                    className="py-3  px-4 d-flex align-items-center text-decoration-none"
                    to="mailto:contact@kypi.io"
                    style={{
                        color: 'var(--color2)'
                    }}
                >
                    {/* <GeneratePdf /> */}

                </NavLink>
            </Offcanvas.Body>
        </Offcanvas >
    )
}
export default OffCanvas