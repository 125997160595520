import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Bar from '../../components/charts/Bar';
import Pie from '../../components/charts/Pie';
import ChartHeading from '../../components/shared/ChartHeading';
import CustomCard from '../../components/shared/CustomCard';
import DashboardTable from '../../components/shared/Table';
import TableHeading from '../../components/shared/TableHeading';
import Pagination from '../../components/shared/TablePagination';
import { getChartData, getChartDataPie, getStatsData, getTableCsv, getTableData } from '../../services/adminService/adminService';
import { DashboardColors } from '../../utils/Static';
import MobileBarChart from '../../components/Mobilecomponents/MobileBarChart';
import { BiDownload } from 'react-icons/bi';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
const Dashboard = ({ selectedRange, showImage }) => {
    console.log("Shiw", showImage)
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState(true);
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [appIdShow, setAppId] = useState();
    const [chartData, setChartData] = useState(null);
    const [pieChartData, setPieChartData] = useState(null);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [stats, setStats] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [companyId, setCompanyId] = useState(localStorage.getItem('selectedCompany'));

    const DashboardtableHeadings = [
        "Rank",
        "Campaign",
        "App ID",
        "Week",
        "Country",
        tableData?.data?.length > 0 ? Object?.keys(tableData?.data[0])[4] : "FTD’s (unique)",
        ...(tableData?.tabsShown?.includes("overview") ? ["Budget"] : [])
    ];

    const DashboardtableHeadings2 = [
        "Rank",
        "Campaign",
        "Week",
        "Country",
        tableData?.data?.length > 0 ? Object?.keys(tableData?.data[0])[4] : "FTD’s (unique)",
        ...(tableData?.tabsShown?.includes("overview") ? ["Budget"] : [])
    ];

    const tableHeadings = () => {
        if (appIdShow) return DashboardtableHeadings;
        return DashboardtableHeadings2;
    };

    const tableBody = tableData?.data?.map((data) => {
        const row = [
            data?.rank,
            data?.campaign,
            ...(appIdShow ? [data?.appId] : []),
            data?.week,
            data?.countryCode,
            ...(appIdShow ? [Object.values(data)[4]] : [Object.values(data)[4]]),
            ...(tableData?.tabsShown?.includes("overview")
                ? [`${new Intl.NumberFormat('fr-FR').format(data?.Budget)} ${tableData?.currency || ''}`]
                : [])];
        return row.filter(value => value != null);
    }) || [];
    const dashboardCards = stats?.dynamicResults
        ? Object.entries(stats.dynamicResults).map(([label, value], index) => ({
            label: label,
            value: index === 0
                ? `${new Intl.NumberFormat('fr-FR').format(value)} ${stats?.currency || ''}`
                : `${new Intl.NumberFormat('fr-FR').format(value)}`,
        }))
        : [];

    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getStatsData(formattedStartDate, formattedEndDate, companyId);
            setStats(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const getChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setChartLoading(true);
            const response = await getChartData(formattedStartDate, formattedEndDate, companyId);
            setChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setChartLoading(false);
        } finally {
            setChartLoading(false);
        }
    }
    const getPieChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieChartLoading(true);
            const response = await getChartDataPie(formattedStartDate, formattedEndDate, companyId);
            setPieChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getTable = async (page, formattedStartDate, formattedEndDate) => {
        try {
            setTableLoading(true);
            const response = await getTableData(formattedStartDate, formattedEndDate, page, 7, companyId);
            console.log("Table data dash", response?.data?.data)
            setTableData(response?.data?.data);
            setTotalPages(response?.data?.data?.totalPages);
            setAppId(response?.data?.data?.appIdEnabled)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setTableLoading(false);
        } finally {
            setTableLoading(false);
        }
    }
    // const handleDownloadCsv = async () => {
    //     Swal.fire({
    //         title: "Are you sure?",
    //         text: "Do you want to download the Table Data?",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#1cc76d",
    //         cancelButtonColor: "#6C757D",
    //         confirmButtonText: "Yes, download it!",
    //         cancelButtonText: "No, cancel",
    //         allowOutsideClick: false,
    //         preConfirm: () => {
    //             Swal.showLoading();
    //             return new Promise(async (resolve, reject) => {
    //                 try {
    //                     const response = await getTableCsv(startDate, endDate, companyId);
    //                     const data = response?.data;
    //                     console.log("Response Data", response);

    //                     if (data && data.length > 0) {
    //                         const blob = new Blob([data], { type: "text/csv" });
    //                         const link = document.createElement("a");
    //                         link.href = window.URL.createObjectURL(blob);
    //                         link.download = `Overview-${user?.companyName}-${startDate}-${endDate}.csv`;

    //                         document.body.appendChild(link);
    //                         link.click();
    //                         document.body.removeChild(link);
    //                         resolve(); // Resolve the promise for successful download
    //                     } else {
    //                         reject("No data available for export");
    //                     }
    //                 } catch (error) {
    //                     reject("Error downloading the file. Please try again."); // Reject with an error message
    //                 }
    //             });
    //         }
    //     })
    //         .then((result) => {
    //             if (result.isConfirmed) {
    //                 Swal.fire({
    //                     title: "Downloaded!",
    //                     text: "The CSV file has been downloaded successfully.",
    //                     icon: "success",
    //                     confirmButtonColor: "#28a745",
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             Swal.fire({
    //                 title: "Error!",
    //                 text: error || "An unexpected error occurred during the download.",
    //                 icon: "error",
    //                 confirmButtonColor: "#dc3545",
    //             });
    //         });
    // };
    const handleDownloadCsv = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to download the Table Data?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            confirmButtonText: "Yes, download it!",
            cancelButtonText: "No, cancel",
            allowOutsideClick: false,
            preConfirm: () => {
                Swal.showLoading();
                return new Promise(async (resolve, reject) => {
                    try {
                        const response = await getTableCsv(startDate, endDate, companyId);
                        const fileUrl = response?.data?.fileUrl;

                        if (fileUrl) {
                            const link = document.createElement("a");
                            link.href = fileUrl;
                            link.download = fileUrl.split("/").pop();
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            resolve();
                        } else {
                            reject("File URL not found in response");
                        }
                    } catch (error) {
                        console.error("Error downloading file:", error);
                        reject("Error downloading the file. Please try again.");
                    }
                });
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Downloaded!",
                        text: "The XLSX file has been downloaded successfully.",
                        icon: "success",
                        confirmButtonColor: "#28a745",
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error || "An unexpected error occurred during the download.",
                    icon: "error",
                    confirmButtonColor: "#dc3545",
                });
            });
    };
    useEffect(() => {
        console.log("Company Id again", companyId)
        const handleStorageChange = () => {
            setUser(JSON.parse(localStorage.getItem("user")) || {});
        };
        const handleCompanyChange = () => {
            setCompanyId(localStorage.getItem('selectedCompany'));
        };
        window.addEventListener('user-update', handleCompanyChange);
        window.addEventListener('user-update', handleStorageChange);

        return () => {
            window.removeEventListener('user-update', handleCompanyChange);
            window.addEventListener('user-update', handleStorageChange);
        };
    }, [companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getStats(formattedStartDate, formattedEndDate);
            getChart(formattedStartDate, formattedEndDate);
            getPieChart(formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, companyId]);

    useEffect(() => {
        console.log("Message")
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            getTable(currentPage, formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, currentPage, companyId]);
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Container className={`mb-5 ${showImage ? 'px-5' : 'dashboard-page'}`} fluid>
            <CustomCard data={dashboardCards} loading={loading} />
            <Row>
                <Col xl={8}>
                    <div className='bg-white rounded-4 p-4 my-md-4 mt-4'>
                        <ChartHeading heading={"Performances"} />
                        <div className="d-lg-none d-block mb-4"></div>
                        <div className='d-lg-block d-none'>
                            <Bar data={chartData} loading={chartLoading} />
                        </div>
                        <div className='d-lg-none d-block'>
                            <MobileBarChart data={chartData} loading={chartLoading} />
                        </div>
                    </div>
                </Col>
                <Col xl={4}>
                    <div className='bg-white rounded-4 p-4 my-4'>
                        <ChartHeading heading={"Performances"} />
                        <div className='d-lg-block d-none'>
                            <Pie textWidth={"80%"} height={400} width={400} outerRadius={"60%"} innerRadius={"40%"} COLORS={DashboardColors} data={pieChartData} loading={pieChartLoading} />
                        </div>
                        <div className='d-lg-none d-block'>
                            <Pie textWidth={"50%"} height={300} width={300} outerRadius={"70%"} innerRadius={"30%"} COLORS={DashboardColors} data={pieChartData} loading={pieChartLoading} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={`${showImage ? 'pb-5' : ''}`}>
                    <div className={`p-4 bg-white rounded-4 m-1 h-100 ${showImage ? '' : ''}`}>
                        <div className='d-flex justify-content-between align-items-center mb-md-4'>
                            <TableHeading heading={"By Campaign"} />
                            <div className='d-lg-flex gap-3 d-none align-items-center'>
                                {tableLoading ? (
                                    <Skeleton className='mb-2' width={40} height={25} />
                                ) : (
                                    <>
                                        {user.showExportTable && (
                                            <Button
                                                className="btn-sm d-flex align-items-center gap-1 border-0"
                                                style={{ backgroundColor: 'transparent', color: 'var(--color2)' }}
                                                onClick={handleDownloadCsv}
                                            >
                                                <BiDownload color="var(--color2)" size={25} />
                                            </Button>
                                        )}
                                    </>
                                )}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onNext={handleNext}
                                    onPrevious={handlePrevious}
                                    loading={tableLoading}
                                />

                            </div>
                            {user.showExportTable && (
                                <Button
                                    className="btn-sm d-lg-none d-flex align-items-center gap-1 border-0"
                                    style={{ backgroundColor: 'transparent', color: 'var( --color2)' }}
                                    onClick={handleDownloadCsv}
                                >
                                    <BiDownload color="var(--color2)" size={25} />
                                </Button>
                            )}
                        </div>
                        <DashboardTable
                            tableBody={tableBody}
                            tableHeadings={tableHeadings()}
                            loading={tableLoading}
                        />
                        <div className='d-lg-none d-block'>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onNext={handleNext}
                                onPrevious={handlePrevious}
                                loading={tableLoading}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >
    );
};
export default Dashboard;