import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
const SecondMetricSelection = ({ formData, fetchAttributeValuesbudget, attributeOptions, setFormData, valueOptionsmetric }) => {

    const handleMetricChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            metrics: { ...prev.metrics, [field]: value }
        }));
    };

    return (
        <div className="">
            <p className="text-secondary form-label py-2">Second Metrics in Metrics Tab</p>
            <div className="px-2">
                <Row className="border py-2 gy-2 align-items-center">
                    <Col sm={6}>
                        <Form.Group controlId={`Matric-5`}>
                            <Form.Label className="text-secondary mb-0"> Label</Form.Label>
                            <Form.Control
                                type='text'
                                name={`matric-label`}
                                value={formData?.metrics?.label}
                                onChange={e => handleMetricChange('label', e.target.value)}
                                placeholder={`Metric Label`}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="formAttribute-Matric">
                            <Form.Label className="text-secondary  mb-0">Select Metric Column</Form.Label>
                            <Form.Select value={formData?.metrics?.name}
                                onChange={e => {
                                    const selectedName = e.target.value;
                                    handleMetricChange('name', selectedName);
                                    fetchAttributeValuesbudget(selectedName, 'metric');
                                }}
                            >
                                <option value="">Select Column</option>
                                {attributeOptions?.map(attr => (
                                    <option key={attr.value} value={attr.value}>{attr.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="formValue">
                            <Form.Label className="text-secondary  mb-0">Select Metric Value</Form.Label>
                            <Form.Select value={formData?.metrics?.value} onChange={e => setFormData(prev => ({
                                ...prev, metrics: { ...prev?.metrics, value: e.target.value }
                            }))} disabled={!formData?.metrics?.name}>
                                <option value="">Select Value</option>
                                {valueOptionsmetric?.map(val => (
                                    <option key={val.value} value={val.value}>{val.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={`selectOption-metric`}>
                            <Form.Label className="text-secondary mb-0"> Metric Option</Form.Label>
                            <Form.Select value={formData?.metrics?.select} onChange={e => handleMetricChange('select', e.target.value)}>
                                <option value="count">Count</option>
                                <option value="sum">Sum</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SecondMetricSelection
