import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Top3Chart from '../../components/charts/Podium';
import CustomCard from '../../components/shared/CustomCard';
import DashboardTable from '../../components/shared/Table';
import TableHeading from '../../components/shared/TableHeading';
import Pagination from '../../components/shared/TablePagination';
import { getCreativeTableData, getStatsData, getTopChart } from '../../services/adminService/adminService';
import MobilePodium from '../../components/Mobilecomponents/MobilePodium';
const Creatives = ({ selectedRange, showImage }) => {
    const [companyId, setCompanyId] = useState(localStorage.getItem('selectedCompany'));
    const [currentPage, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [topCreatives, setTopCreatives] = useState([]);
    const [tabsShow, setTabsShow] = useState([]);
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [stats, setStats] = useState(null);
    const [ftdShow, setFtdSow] = useState();
    const [useFtd, setUseFtd] = useState(false);
    const [totalPages, setTotalPages] = useState(5);
    const dashboardCards = stats?.dynamicResults
        ? Object.entries(stats.dynamicResults).map(([label, value], index) => ({
            label: label,
            value: index === 0
                ? `${new Intl.NumberFormat('fr-FR').format(value)} ${stats?.currency || ''}`
                : `${new Intl.NumberFormat('fr-FR').format(value)}`,
        }))
        : [];
    const DashboardtableHeadings = [
        "Rank",
        "Creative Name",
        tableData?.data?.length > 0 ? Object?.keys(tableData?.data[0])[2] : "FTD’s (unique)",
        tableData?.data?.length > 0 ? `${Object?.keys(tableData?.data[0])[4]}` : "%FTD’s (unique)",
        ...(tabsShow?.includes("creatives") ? ["Budget"] : [])
    ];

    const DashboardtableHeadings2 = [
        "Rank",
        "Creative Name",
        tableData?.data?.length > 0 ? `${Object?.keys(tableData?.data[0])[3]}` : "%FTD’s (unique)",
        ...(tabsShow?.includes("creatives") ? ["Budget"] : [])
    ];
    const DashboardtableHeadings3 = [
        "Rank",
        "Creative Name",
        tableData?.data?.length > 0 ? Object?.keys(tableData?.data[0])[2] : "FTD’s (unique)",
        tableData?.data?.length > 0 ? `${Object?.keys(tableData?.data[0])[3]}` : "%FTD’s (unique)",
    ];

    const DashboardtableHeadings4 = [
        "Rank",
        "Creative Name",
        tableData?.data?.length > 0 ? `${Object?.keys(tableData?.data[0])[2]}` : "%FTD’s (unique)",
    ];
    const tableHeadings = () => {
        if (ftdShow && useFtd) return DashboardtableHeadings3;
        else if (!ftdShow && useFtd) return DashboardtableHeadings4
        else if (ftdShow && !useFtd) return DashboardtableHeadings
        return DashboardtableHeadings2;
    };
    const tableBody = tableData?.data?.map((data) => {
        const row = [
            data?.rank,
            data?.CreativeName,
            ...(ftdShow ? [Object?.values(data)[2]] : []),
            ...(useFtd
                ? (ftdShow
                    ? [`${Object?.values(data)[3]}%`]
                    : [`${Object?.values(data)[2]}%`]
                )
                : (ftdShow ? [`${Object?.values(data)[4]}%`] : [`${Object?.values(data)[3]}%`])
            ),
            ...(tabsShow?.includes("creatives") && !useFtd
                ? [`${new Intl.NumberFormat('fr-FR').format(data?.Budget)} ${tableData?.currency || ''}`]
                : [])];
        return row.filter(value => value != null);
    }) || [];
    const getTable = async (page, formattedStartDate, formattedEndDate) => {
        try {
            setTableLoading(true);
            const response = await getCreativeTableData(formattedStartDate, formattedEndDate, page, 10, companyId);
            console.log("Table Data", response?.data?.data)
            setTableData(response?.data?.data?.responseData);
            setTabsShow(response?.data?.data?.tabsShown);
            setTotalPages(response?.data?.data?.responseData?.totalPages);
            setFtdSow(response?.data?.data?.responseData?.ftdEnabled)
            setUseFtd(response?.data?.data?.responseData?.useFtd);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setTableLoading(false);
        } finally {
            setTableLoading(false);
        }
    }
    const getTopChartPodium = async (formattedStartDate, formattedEndDate) => {
        setPieChartLoading(true);
        try {
            setPieChartLoading(true);
            const response = await getTopChart(formattedStartDate, formattedEndDate, companyId);
            setTopCreatives(response?.data?.topCreatives);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getStatsData(formattedStartDate, formattedEndDate, companyId);
            setStats(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        console.log("Company Id again", companyId)

        const handleCompanyChange = () => {
            setCompanyId(localStorage.getItem('selectedCompany'));
        };
        window.addEventListener('user-update', handleCompanyChange);

        return () => {
            window.removeEventListener('user-update', handleCompanyChange);
        };
    }, [companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getStats(formattedStartDate, formattedEndDate);
            getTopChartPodium(formattedStartDate, formattedEndDate);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRange, companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getTable(currentPage, formattedStartDate, formattedEndDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRange, currentPage, companyId]);
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (

        <Container className={`mb-5 ${showImage ? 'px-5' : 'dashboard-page'}`} fluid>
            <CustomCard data={dashboardCards} loading={loading} />

            <Row className='mt-4 flex-xl-row flex-column-reverse'>
                <Col xl={7}>
                    <div className='p-4 bg-white rounded-4 m-1 h-100 '>
                        <div className='d-md-flex justify-content-between   align-items-center'>
                            <TableHeading heading={"By Campaign"} />
                            <div className='d-lg-block d-none'>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onNext={handleNext}
                                    onPrevious={handlePrevious}
                                    loading={tableLoading}
                                />
                            </div>
                        </div>
                        <div className=" mt-lg-3 d-flex  justify-content-end align-items-center">
                            <div className="w-100">
                                <DashboardTable
                                    tableBody={tableBody}
                                    tableHeadings={tableHeadings()}
                                    loading={tableLoading}
                                    count={'10'}
                                />
                            </div>
                        </div>
                        <div className='d-lg-none d-block'>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onNext={handleNext}
                                onPrevious={handlePrevious}
                                loading={tableLoading}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={5} className={`${showImage ? 'pb-5' : ''}`}>
                    <div className={`py-4 px-3 bg-white rounded-4 m-1 h-100 mb-lg-0 mb-4 ${showImage ? '' : ''}`}>
                        <div className='d-flex justify-content-between align-items-center mb-lg-5 mb-3'>
                            <TableHeading heading={"Podium"} />
                        </div>
                        <div className='d-lg-block d-none'>
                            <Top3Chart data={topCreatives} loading={pieChartLoading} />
                        </div>
                        <div className='d-lg-none d-block'>
                            <MobilePodium data={topCreatives} loading={pieChartLoading} />
                        </div>
                    </div >
                </Col>
            </Row>
        </Container >

    );
};

export default Creatives;