import logo from '../assets/logo/Logo.svg';
import { Dropdown, Image } from 'react-bootstrap';
import { useAuth } from '../utils/AuthContext';
import GeneratePdf from '../Pdf';
import { BiPowerOff } from 'react-icons/bi';
import CompanySelection from './CompanySelection';
import { IoMdLogOut } from 'react-icons/io';

const MobileNavbar = ({ onLogoClick, setShowImage, setProgress, selectedRange }) => {
    const { logout } = useAuth();
    return (
        <div className={`mobile-navbar d-lg-none d-flex justify-content-between align-items-center w-100 p-2`}        >
            <Dropdown>
                <Dropdown.Toggle
                    as="span"
                    id="dropdown-custom-components"
                    className="e-caret-hide"
                    style={{ cursor: 'pointer' }}
                >
                    <Image src={logo} alt="Logo" style={{ height: '32px' }} />
                </Dropdown.Toggle>
            </Dropdown>
            <div className='d-flex align-items-center gap-2'>
                <CompanySelection />
                <div style={{ height: '13px', color: '#C9C9C9', backgroundColor: '#C9C9C9', width: '1px' }}></div>
                {/* <GeneratePdf setShowImage={setShowImage} setProgress={setProgress} selectedRange={selectedRange} /> */}
                {/* <div style={{ height: '13px', color: '#C9C9C9', backgroundColor: '#C9C9C9', width: '1px' }}></div> */}
                <IoMdLogOut color="var(--color2)" size={20} height={15} onClick={logout} />
            </div>
        </div>
    );
};

export default MobileNavbar;
