import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import UsersTable from '../../components/adminTable/UsersTable'
import { GetUsers } from '../../utils/admin/admin.service'
import AddUser from './AddUser'

const UserManagement = () => {
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const FormClose = () => setShowAddUserModal(false);

    const GetUser = async () => {
        let response;
        setLoading(true);
        try {
            response = await GetUsers();
            setUsers(response?.data?.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        GetUser();
        // eslint-disable-next-line
    }, []);
    return (
        <Container fluid>
            <div className="mt-4">
                {!loading && (
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 style={{ color: '#4E4E4E' }} className="mt-4">
                            Users
                        </h4>
                        <Button
                            className="btn-custom px-3 fw-bold d-flex align-items-center"
                            onClick={() => setShowAddUserModal(true)}
                            style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
                        >
                            <span className="me-2">&#43;</span>
                            Add User
                        </Button>
                    </div>
                )}
                <UsersTable tableBody={users} loading={loading} reCall={GetUser} />
                <AddUser
                    reCall={GetUser}
                    show={showAddUserModal}
                    close={FormClose}
                />
            </div>
        </Container>
    )
}

export default UserManagement
