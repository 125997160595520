import { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { MdOutlineCalendarToday } from 'react-icons/md';
import { getChartAttribute, getChartCountry, getCtrStatsData, getDayChartData, getTableData } from '../services/adminService/adminService';
import analyticsBox from '../assets/svg/analyticsBox.svg';
import geo from '../assets/svg/geodark.svg';
import DayDistributionChart from '../components/charts/metricsChart';
import Pie from '../components/charts/Pie';
import ChartHeading from '../components/shared/ChartHeading';
import CustomCard from './PdfCustomCard';
import DashboardTable from '../components/shared/Table';
import TableHeading from '../components/shared/TableHeading';
import Pagination from '../components/shared/TablePagination';
import PdfHeader from '../components/shared/PdfHeader';
import PdfFooter from '../components/shared/PdfFooter';
const Metrics = ({ selectedRange, showImage }) => {
    const [companyId, setCompanyId] = useState(localStorage.getItem('selectedCompany'));
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState(true);
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [pieAttributeChartLoading, setPieAttributeChartLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [chartData, setChartData] = useState(null);
    const [appIdShow, setAppId] = useState();
    const [pieChartData, setPieChartData] = useState(null);
    const [pieAttributeChartData, setPieAttributeChartData] = useState(null);
    const [stats, setStats] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const MetricsCardsData = stats?.dynamicResults
        ? Object.entries(stats.dynamicResults).map(([label, value], index) => {
            const formattedValue = Number.isInteger(value)
                ? `${new Intl.NumberFormat('fr-FR').format(value)} ${index === 0 ? stats?.currency || '' : ''}`
                : `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 2 }).format(value)} ${index === 0 ? stats?.currency || '' : ''}`;

            return {
                label: label,
                value: formattedValue,
            };
        })
        : [];


    const DashboardtableHeadings = [
        "Rank",
        "Campaign",
        "App ID",
        "Week",
        "Country",
        tableData?.length > 0 ? Object?.keys(tableData[0])[4] : "FTD’s (unique)",
        ...(tableData?.tabsShown?.includes("metrics") ? ["Budget"] : [])

    ];
    const DashboardtableHeadings2 = [
        "Rank",
        "Campaign",
        "Week",
        "Country",
        tableData?.data?.length > 0 ? Object?.keys(tableData?.data[0])[4] : "FTD’s (unique)",
        ...(tableData?.tabsShown?.includes("metrics") ? ["Budget"] : [])
    ];
    const tableHeadings = () => {
        if (appIdShow) return DashboardtableHeadings;
        return DashboardtableHeadings2;
    };
    const tableBody = tableData?.data?.map((data) => {
        const row = [
            data?.rank,
            data?.campaign,
            ...(appIdShow ? [data?.appId] : []),
            data?.week,
            data?.countryCode,
            // ...(ftdShow ? [data?.FTDUnique] : [])
            [Object?.values(data)[4]],
            ...(tableData?.tabsShown?.includes("metrics")
                ? [`${new Intl.NumberFormat('fr-FR').format(data?.Budget)} ${tableData?.currency || ''}`]
                : [])];
        return row.filter(value => value != null);
    }) || [];
    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getCtrStatsData(formattedStartDate, formattedEndDate, companyId);
            console.log("States", response?.data?.data)
            setStats(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const getDayChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setChartLoading(true);
            const response = await getDayChartData(formattedStartDate, formattedEndDate, companyId);
            setChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setChartLoading(false);
        } finally {
            setChartLoading(false);
        }
    }
    const getPieChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieChartLoading(true);
            const response = await getChartCountry(formattedStartDate, formattedEndDate, companyId);
            setPieChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getAttributeChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieAttributeChartLoading(true);
            const response = await getChartAttribute(formattedStartDate, formattedEndDate, companyId);
            setPieAttributeChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieAttributeChartLoading(false);
        } finally {
            setPieAttributeChartLoading(false);
        }
    }
    const getTable = async (page, formattedStartDate, formattedEndDate) => {
        try {
            setTableLoading(true);
            const response = await getTableData(formattedStartDate, formattedEndDate, page, 7, companyId);
            setTableData(response?.data?.data);
            setTotalPages(response?.data?.data?.totalPages);
            setAppId(response?.data?.data?.appIdEnabled)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setTableLoading(false);
        } finally {
            setTableLoading(false);
        }
    }
    useEffect(() => {
        console.log("Company Id again", companyId)

        const handleCompanyChange = () => {
            setCompanyId(localStorage.getItem('selectedCompany'));
        };
        window.addEventListener('user-update', handleCompanyChange);

        return () => {
            window.removeEventListener('user-update', handleCompanyChange);
        };
    }, [companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getStats(formattedStartDate, formattedEndDate)
            getDayChart(formattedStartDate, formattedEndDate);
            getPieChart(formattedStartDate, formattedEndDate);
            getAttributeChart(formattedStartDate, formattedEndDate);

        }
    }, [selectedRange, companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getTable(currentPage, formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, currentPage, companyId]);
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const colors1 = [
        "var(--color4)",

    ];
    const colors2 = [
        "var(--color1)",
        "var(--color2)",
        "var(--color2)",
        "var(--color2)",

    ];

    return (
        <div id="pdf" className='pdf-size'>
            <div style={{
                backgroundImage: showImage
                    ? "url('https://res.cloudinary.com/djmk3gpgr/image/upload/v1730396033/skhbjvc4cy1zxtzudjvm.png')"
                    : "none",
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                backgroundPosition: 'top center'
            }}
            >
                <PdfHeader showImage={showImage} />
                <Container className={`mb-5 ${showImage ? 'px-5' : ''}`} fluid>
                    <CustomCard data={MetricsCardsData} loading={loading} />
                    <Row>
                        <Col xs={6} >
                            <div className='bg-white rounded-4 p-4 my-4'>
                                <ChartHeading color="var(--color4)"
                                    icon={<MdOutlineCalendarToday color="var(--color4)" className="fs-5  mb-1  " />
                                    } heading={"Day Distribution"} />
                                <div className="mt-5">
                                    <DayDistributionChart data={chartData} loading={chartLoading} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} >
                            <div className='bg-white rounded-4 p-4 my-4   metrics-pie-h' >
                                <ChartHeading icon={<Image src={geo} alt="G" />} heading={"Country Distribution"} />
                                <Pie COLORS={colors1} data={pieChartData} outerRadius={"50%"} innerRadius={"30%"} height={300} width={400} loading={pieChartLoading} />
                            </div>
                        </Col>
                        <Col xl={3} lg={6}  >
                            <div className='bg-white rounded-4 p-md-4 my-4   metrics-pie-h'  > <ChartHeading icon={<Image src={analyticsBox} alt="A" className="fs-3" />
                            } heading={pieAttributeChartData?.attrLabel || "%FTD’s Attributed"} />
                                <Pie COLORS={colors2} data={pieAttributeChartData?.pieData} outerRadius={"50%"} innerRadius={"30%"} height={300} width={400} loading={pieAttributeChartLoading} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={`${showImage ? 'pb-5' : ''}`}>
                            <div className={`p-5 bg-white rounded-4 m-1 ${showImage ? '' : ''}`}>
                                <div className='d-flex justify-content-between align-items-center mb-4'>
                                    <TableHeading heading={"By Campaign"} />
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onNext={handleNext}
                                        onPrevious={handlePrevious}
                                    />                    </div>
                                <DashboardTable tableBody={tableBody} tableHeadings={tableHeadings()} loading={tableLoading} />
                            </div>
                        </Col>
                    </Row>
                </Container >
            </div>
            <PdfFooter showImage={showImage} />
        </div>
    );
};
export default Metrics;
