import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { deleteCompany, GetCompany, UpdateCompanyStatus } from '../../utils/admin/admin.service';
import Skeleton from 'react-loading-skeleton';
// import { toast } from 'react-toastify';
import UserCard from '../../components/shared/UserCard';
import EditCompanyModal from '../../components/modals/EditCompanyModal';
import Swal from 'sweetalert2';

const AdminDashboard = () => {
    const [company, setCompany] = useState();
    const [loading, setLoading] = useState(true)
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditClick = () => {
        setShowEditModal(true);
    };

    const handleModalClose = () => {
        setShowEditModal(false);
        // allCompanies();
    };
    const [limit, setLimit] = useState(4);

    const allCompanies = async () => {
        setLoading(true);
        try {
            const response = await GetCompany();
            setCompany(response?.data?.companies);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const handleToggleChange = async (companyId, currentStatus) => {
        const newStatus = currentStatus === true ? false : true;
        Swal.fire({
            title: "Are you sure?",
            text: `You want to ${currentStatus ? ('Deactivate') : ('Activate')}  this company.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            confirmButtonText: `Yes, ${currentStatus ? ('deactivate') : ('activate')} it!`,
            cancelButtonText: "No, cancel",
            allowOutsideClick: false,
            preConfirm: () => {
                Swal.showLoading();
                return new Promise(async (resolve, reject) => {
                    try {
                        await UpdateCompanyStatus(companyId, newStatus);
                        resolve();
                    } catch (error) {
                        reject(error?.response?.data?.error || 'Network Error');
                    }
                });
            }
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Updated!",
                    text: `The company has been ${currentStatus ? ('Deactivated') : ('Activated')} successfully.`,
                    icon: "success",
                    confirmButtonColor: "#28a745",
                });
                allCompanies();
            }
        }).catch((error) => {
            Swal.fire({
                title: "Error!",
                text: error || 'An error occurred while updating the company information.',
                icon: "error",
                confirmButtonColor: "#dc3545",
            });
        });
    };

    const handleDelete = async (companyId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this company.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",
            allowOutsideClick: false,
            preConfirm: () => {
                Swal.showLoading();
                return new Promise(async (resolve, reject) => {
                    try {
                        await deleteCompany(companyId);
                        resolve();
                    } catch (error) {
                        reject(error?.response?.data?.error || 'Network Error');
                    }
                });
            }
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Updated!",
                    text: "The company has been deleted successfully.",
                    icon: "success",
                    confirmButtonColor: "#28a745",
                });
                allCompanies();
            }
        }).catch((error) => {
            Swal.fire({
                title: "Error!",
                text: error || 'An error occurred while deleting the company information.',
                icon: "error",
                confirmButtonColor: "#dc3545",
            });
        });
    };
    useEffect(() => {
        allCompanies()
    }, [])
    return (
        <>
            <Container className='' fluid>
                <Row className='pb-4 pt-2'>
                    <Col xs={12}>
                        <Form className='d-flex justify-content-end align-items-center px-3'>
                            <div className='d-flex align-items-center gap-2 w-100'>
                                <h1 className='mb-0 pb-0'>Company Management</h1>
                            </div>
                            <Button onClick={handleEditClick} style={{ width: "170px", background: "var(--bg-ltr)", borderColor: "var( --color2)" }} className='rounded-3'>
                                <span className="mb-0 fw-bold pe-1">&#43;</span>
                                <span className="mb-0 fs-12">New Company</span>
                            </Button>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    {loading ? (
                        Array.from({ length: limit }).map((_, index) => (
                            <Col xs={12} lg={3} key={index}>
                                <Card
                                    className='text-center my-3 py-2 border-0'
                                    style={{
                                        borderRadius: "20px",
                                        overflow: "hidden",
                                        backgroundColor: "#ffffff",
                                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    }}
                                >
                                    <div className='text-end me-2'>
                                        <Skeleton width={24} height={24} circle />
                                    </div>
                                    <div style={{ position: 'relative', width: "100px", height: "100px", margin: "auto" }}>
                                        <Skeleton width={"100%"} height="100%" style={{ borderRadius: "60px", overflow: "hidden" }} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title>
                                            <Skeleton width="60%" height={24} />
                                        </Card.Title>
                                        <Card.Text>
                                            <Skeleton width="80%" height={20} />
                                        </Card.Text>
                                        <label>
                                            <Skeleton width={40} height={20} />
                                        </label>
                                        <div className="d-flex gap-4 justify-content-center mt-1">
                                            <Skeleton width={30} height={30} circle />
                                            <Skeleton width={30} height={30} circle />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : !company || company?.length === 0 ? (
                        <Col xs={12}>
                            <div className="text-center mt-5 mb-2" style={{ height: '51vh' }}>
                                <h5 className='mb-0 pb-0'>No Results Found</h5>
                            </div>
                        </Col>
                    ) : (
                        company?.map(comp => (
                            <Col key={comp?.id} xxl={3} xl={3} lg={4} md={6} xs={12}>
                                <UserCard
                                    id={comp?._id}
                                    Name={comp?.name}
                                    CreatedAt={comp?.createdAt}
                                    image={comp?.logo}
                                    status={comp?.status}
                                    Phone={comp?.phone}
                                    handleToggleChange={() => handleToggleChange(comp?._id, comp?.status)}
                                    handleDelete={() => handleDelete(comp?._id)}
                                />
                            </Col>
                        ))
                    )}
                </Row>
            </Container>
            <EditCompanyModal
                show={showEditModal}
                onHide={handleModalClose}
                company={company}
                reCall={allCompanies}
            />
        </>
    );
};

export default AdminDashboard;
