import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import pdfStartImage from './assets/EndPage.png';

const CustomEndpage = () => {
  const [user,] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
  return (
    <Container fluid
      id='pdf'
      className="pdfstart d-flex justify-content-between  flex-column py-4"
      style={{
        backgroundImage: `url(${pdfStartImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '635px', // Approx A4 width in pixels
        height: '900px', // Approx A4 height in pixels
      }}
    >
      <div className="d-flex justify-content-between align-items-center px-2">
        <div style={{ width: "100%" }}>
          <img src={"https://res.cloudinary.com/djmk3gpgr/image/upload/v1730471190/qmwsefrmljgbcpfuwaqh.png"} alt="logo" width={100} height={100} className="img-fluid" />
        </div>
        <div className="card border-0 shadow p-2" style={{ background: '#09332C' }}>
          <img
            src={user?.logo}
            alt="Company Logo"
            style={{ width: "30px", objectFit: "scale-down" }}
          />
        </div>
      </div>
      <Container className='px-5'>
        <div className='d-flex align-items-start justify-content-start gap-5 flex-column'>
          <div>
            <h1 style={{ color: "#09332C", fontFamily: "Sora", fontSize: '96px', lineHeight: '91.2px', letterSpacing: '0.03em' }} className=''>Thank</h1>
            <h1 style={{ color: "#007755", fontFamily: "Sora", fontSize: '96px', lineHeight: '91.2px', letterSpacing: '0.03em' }} className='mb-4 pt-2'>you!</h1>
          </div>
        </div>
      </Container>
      <div className='px-1'>
        <Row className=''>
          <Col xs={5} className='mt-auto'>
            <p className='mb-0' style={{ color: '#00000080', fontSize: '10px', fontWeight: '400', fontFamily: 'Inter' }}>Copyright © 2024 KyPI</p>
          </Col>
          <Col xs={3}>
            <p style={{ color: '#00000080', fontSize: '12px', fontWeight: '600', fontFamily: 'Inter', marginBottom: '1px' }} className=''>France</p>
            <p className='mb-0 mt-0' style={{ color: '#00000080', fontSize: '10px', fontWeight: '400', fontFamily: 'Inter', letterSpacing: '0.03em' }}>
              <Col xs={10}>
                26 rue Bosquet 75007 Paris
              </Col>
            </p>
          </Col>
          <Col xs={4}>
            <p style={{ color: '#00000080', fontSize: '12px', fontWeight: '600', fontFamily: 'Inter', marginBottom: '1px' }} className=''>Brésil</p>
            <p className='mb-0 mt-0' style={{ color: '#00000080', fontSize: '10px', fontWeight: '400', fontFamily: 'Inter', }}>
              R.Santa Cruz2187 Vila Mariana São Paulo SP Brazil
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CustomEndpage;
