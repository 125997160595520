import React, { useEffect, useState } from 'react';
import CompaniesModal from './CompaniesModal';
import { Button } from 'react-bootstrap';
import { GetUserRoles } from '../utils/auth.utils';
import { GetUserCompanies } from '../services/companyService/companyService';
import { MdOutlineSwitchAccount } from 'react-icons/md';
import { PiUserSwitch } from 'react-icons/pi';

const CompanySelection = () => {
    const [selectedCompany, setSelectedCompany] = useState('');
    const [companies, setCompanies] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const UserRole = GetUserRoles();
    const companyId = localStorage.getItem('selectedCompany');

    const handleModalClose = () => {
        setShowModal(false);
    };

    const loadCompanies = async () => {
        setLoading(true);
        try {
            const response = await GetUserCompanies();
            const fetchedCompanies = response?.data?.companies || [];
            setCompanies(fetchedCompanies);
            console.log("user companies", response);

            if (fetchedCompanies.length === 1) {
                const singleCompany = fetchedCompanies[0];
                setSelectedCompany(singleCompany._id);
                localStorage.setItem('selectedCompany', singleCompany._id);

                const userData = JSON.parse(localStorage.getItem('user'));
                if (userData) {
                    userData.logo = singleCompany.logo;
                    userData.siteIDShow = singleCompany.siteIDShow;
                    userData.overviewPerDay = singleCompany.overviewPerDay;
                    userData.showgeo = singleCompany.showgeo;
                    userData.showExport = singleCompany.showExport;
                    userData.showExportTable = singleCompany.showExportTable;
                    userData.cityFranceMap = singleCompany.cityFranceMap;
                    userData.companyName = singleCompany.name;
                    localStorage.setItem('user', JSON.stringify(userData));
                    console.log("User")
                    window.dispatchEvent(new Event('user-update'));
                }
                console.log('Automatically selected single company:', singleCompany);
                setShowModal(false);
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCompanies();
        const storedCompanyId = localStorage.getItem('selectedCompany');
        if (storedCompanyId) {
            setSelectedCompany(storedCompanyId);
        }
    }, []);

    useEffect(() => {
        if (!companyId && UserRole !== 'admin') {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [companyId, UserRole]);

    return (
        <div>
            <CompaniesModal
                show={showModal}
                onHide={handleModalClose}
                companies={companies}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                loading={loading}
            />
            {companies.length > 1 && (
                <>
                    <div className='text-end d-lg-block d-none' onClick={() => setShowModal(true)} >
                        <Button className='border-0 px-4 company-btn-hover' style={{ backgroundColor: 'var( --color1)', color: 'var( --color2)' }}>Account</Button>
                    </div>
                    <Button
                        onClick={() => setShowModal(true)}
                        className='d-lg-none d-block border-0 p-0'
                        style={{
                            backgroundColor: 'transparent'
                        }}
                    >
                        {/* <MdOutlineSwitchAccount color="var(--color2)" size={24} />  */}
                        <PiUserSwitch color="var(--color2)" size={20} height={15} />
                    </Button>
                </>
            )}
        </div>
    );
};

export default CompanySelection;
