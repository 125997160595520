import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import logo from "../../assets/logo/Logo.svg";

const PdfHeader = ({ showImage }) => {
    const [user] = useState(() => JSON.parse(localStorage.getItem("user")) || {});

    const formatTitleFromPath = () => {
        const path = window.location.pathname.split('/').pop();
        return path
            .replace('-pdf', '')
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const pageTitle = formatTitleFromPath();
    return (
        // showImage && (
        <Container className="mb-5 px-5" fluid>
            <div className="d-flex justify-content-between align-items-center py-4">
                <div style={{ width: "100%" }}>
                    <img src={"https://res.cloudinary.com/djmk3gpgr/image/upload/v1730471190/qmwsefrmljgbcpfuwaqh.png"} alt="logo" width={100} height={100} className="img-fluid" />
                </div>
                <div className="card border-0 shadow p-2" style={{ background: '#09332C' }}>
                    <img
                        src={user?.logo}
                        alt="Company Logo"
                        style={{ width: "80px", objectFit: "scale-down" }}
                    />
                </div>
            </div>
            <div className="pt-4">
                <h1 className="page-title pt-3 mb-0" style={{ color: '#007755', fontSize: '66px', fontWeight: '400' }}>
                    {pageTitle === "Geo" ? "Locations" : pageTitle}
                </h1>
            </div>
        </Container>
        // )
    );
};

export default PdfHeader;
