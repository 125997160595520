import MobileNavbar from "../../../components/MobileNavbar";
import { DateRange } from "../../../components/shared/DateRange";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { FiUser } from "react-icons/fi";
import { GoQuestion } from "react-icons/go";
import { MdOutlineCalendarToday } from "react-icons/md";
import { Link } from "react-router-dom";
import { GetUserName, GetUserRoles } from "../../../utils/auth.utils";
import { useAuth } from "../../../utils/AuthContext";
import { useEffect, useState } from "react";
import GeneratePdf from "../../../Pdf";
import CompanySelection from "../../CompanySelection";
import { IoIosHelpCircleOutline } from "react-icons/io";

const SidebarHeader = ({ onLogoClick, setSelectedRange, selectedRange, setShowImage, setProgress }) => {
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
    const { logout } = useAuth();
    const UserRole = GetUserRoles()
    const fullName = GetUserName();
    useEffect(() => {
        const handleStorageChange = () => {
            setUser(JSON.parse(localStorage.getItem("user")) || {});
        };

        window.addEventListener('user-update', handleStorageChange);
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('user-update', handleStorageChange);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    return (
        <Container fluid className="mt-lg-0 mt-5 pt-lg-0 pt-2 mobilepad">
            <MobileNavbar onLogoClick={onLogoClick} setShowImage={setShowImage} setProgress={setProgress} selectedRange={selectedRange} />
            <Row className="pt-3 pb-3 ps-3 pe-lg-3 mt-2 sidebar-header rounded-4">
                <Row className="flex-lg-row flex-column-reverse gap-lg-0 gap-5 " style={{ position: "relative", zIndex: 2 }} >
                    <Col>
                        <h3 className="mb-lg-0 text-white text-nowrap text-capitalize">Welcome, {fullName ? fullName : "john doe"} </h3>
                        <p className="fs-12" style={{ color: "#AAAAAA" }}>
                            Find all your campaign performances right here
                        </p>
                    </Col>
                    {UserRole !== 'admin' ? (
                        <Col>
                            <div className="d-flex justify-content-lg-end justify-content-between align-items-center  gap-md-3 gap-2">
                                <div className="d-lg-block d-none">
                                    <CompanySelection />
                                </div>
                                <div
                                    style={{ borderColor: "var(--color5)!important" }}
                                    className="border d-flex rounded-4 align-items-center "
                                >
                                    <div className="px-lg-3 px-2 py-2" style={{ borderRight: '1px solid #FFFFFF' }}>
                                        <MdOutlineCalendarToday className="fs-4" color="var(--color5)" />
                                    </div>
                                    <DateRange setSelectedRange={setSelectedRange} selectedRange={selectedRange} />
                                </div>
                                <div className="d-lg-block d-flex align-items-center gap-2">
                                    <div style={{ width: '60px', height: '18px' }}>
                                        <Image src={user?.logo} alt="Unibet" className="w-100 h-100 " style={{ objectFit: 'contain' }} />
                                    </div>
                                    <div className="d-lg-none d-block" style={{ height: '36px', color: '#C9C9C9', backgroundColor: '#C9C9C9', width: '1px' }}></div>
                                    <div className="d-lg-none d-block">
                                        <a href="mailto:contact@kypi.io">
                                            <Button
                                                className="d-lg-none d-block border-0 switch-account"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '35px',
                                                    height: '35px',
                                                    borderRadius: '50%',
                                                    padding: 0,
                                                    backgroundColor: '#ffe521'
                                                }}
                                            >
                                                <IoIosHelpCircleOutline color="var(--color2)" size={24} />
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: "auto",
                                        alignSelf: "stretch",
                                    }}
                                    className="vr text-white d-md-block d-none"
                                ></div>
                                <div className="d-lg-flex d-none justify-content-center align-items-center gap-lg-3 gap-0">
                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                        <Button onClick={logout} className="rounded-4 px-3 fs-12  d-flex justify-content-center align-items-center btn-custom">
                                            <FiUser className="me-2" /> Logout
                                        </Button>

                                        <Button as={Link} to="mailto:contact@kypi.io" className="rounded-4 text-decoration-none px-3 fs-12  text-nowrap d-flex justify-content-center align-items-center btn-custom">
                                            <GoQuestion className="me-2" /> Contact us
                                        </Button>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <GeneratePdf setShowImage={setShowImage} setProgress={setProgress} selectedRange={selectedRange} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : (
                        <>
                            <Col>
                                <div className="d-flex justify-content-xl-end align-items-center  gap-3">
                                    <div className="d-md-flex d-none justify-content-center align-items-center gap-3">
                                        <Button onClick={logout} className="rounded-4 px-3 fs-12  d-flex justify-content-center align-items-center btn-custom">
                                            <FiUser className="me-2" /> Logout
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </>
                    )}

                </Row>
            </Row>

        </Container>
    );
};

export default SidebarHeader;
