import React from 'react'
import analytics from '../../assets/svg/analytics.svg'
import { Image } from 'react-bootstrap'
const ChartHeading = ({ heading, icon, color }) => {
    return (
        <div className='d-flex gap-2 align-items-center'>
            {
                icon
                    ? <>{icon} </> :
                    <div className='mb-1 me-2' style={{ width: "10px" }}>
                        <Image src={icon || analytics} alt="I" />
                    </div>
            }
            <h6 className='mb-0' style={{ color: color }} >{heading}</h6>
        </div>
    )
}

export default ChartHeading
