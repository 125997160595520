import React, { useCallback, useMemo } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';

const ExcludeAppId = ({ formData, company, setFormData, additional }) => {
    const categoryOptions = useMemo(() => (
        additional?.appIDs?.map(appID => ({ value: appID, label: appID })) || []
    ), [additional]);

    const handleSelectChange = useCallback((selectedOptions, name) => {
        setFormData(prev => ({
            ...prev,
            [name]: selectedOptions.map(option => option.value)
        }));
    }, [setFormData]);

    return (
        <div>
            {company?.siteIDShow &&
                <Row className="mt-3">
                    <Col xs={12}>
                        <Form.Group controlId="formCategory">
                            <Form.Label className="text-secondary">Select App IDs to Exclude from the Category Table</Form.Label>
                            <Select
                                isMulti
                                options={categoryOptions}
                                value={categoryOptions.filter(option =>
                                    formData?.excludeAppId?.includes(option?.value)
                                )}
                                onChange={selectedOptions => handleSelectChange(selectedOptions, 'excludeAppId')}
                                closeMenuOnSelect={false}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default ExcludeAppId;
